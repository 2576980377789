import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl, getSensorDetails, carbsUrl, insulinUrl, activityUrl } from "../../utils/urls";
import {
  FETCH_BG_START,
  FETCH_BG_FAILED,
  FETCH_BG_SUCCESS,
  FETCH_SENSOR_START,
  FETCH_SENSOR_FAILED,
  FETCH_SENSOR_SUCCESS,
  FETCH_CARBS_START,
  FETCH_CARBS_FAILED,
  FETCH_CARBS_SUCCESS,
  FETCH_INSULIN_START,
  FETCH_INSULIN_FAILED,
  FETCH_INSULIN_SUCCESS,
  FETCH_ACTIVITY_START,
  FETCH_ACTIVITY_FAILED,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_CGM_REPO_SUCCESS,
  FETCH_HISTORY_CGM_RECORD_SUCCESS,
  SAVE_MBG_SUCCESS,
  SAVE_SDBG_SUCCESS,
  SAVE_CV_SUCCESS,
  SAVE_LAGE_SUCCESS,
  SAVE_MAGE_SUCCESS,
  SAVE_JINDEX_SUCCESS,
  SAVE_OGP_SUCCESS,
  SAVE_MVALUE_SUCCESS,
  SAVE_IQR_SUCCESS,
  SAVE_AAC_SUCCESS,
  SAVE_AUC_SUCCESS,
  SAVE_HBGI_SUCCESS,
  SAVE_ADRR_SUCCESS,
  SAVE_HBA1C_SUCCESS,
  SAVE_ADBG_SUCCESS





} from "../actionTypes";
import moment from "moment";


import { encrypt, decrypt } from "../../utils/encryption";

export const fetchBgData = (data) => async (dispatch) => {
  console.log("DATA :", data);
  dispatch({
    type: FETCH_BG_START,
  });
  try {
    let bgTime;
    let res;
    let dayDif;
    let type = data && data.type === 4 ? data.type : 1;
    if (data.time) {
      bgTime = data.time.replace(/ /g, '');
      dayDif = data.time;
      let calculatedStartDate = await findStartDate(data.time);
      let bg_start = calculatedStartDate.date;
      bg_start = bg_start.toISOString();
      bg_start = await encrypt(bg_start);
      let bg_end = new Date();
      bg_end = bg_end.toISOString();
      bg_end = await encrypt(bg_end);
      let encType = type;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);
    } else if (data.start_date) {
      console.log("IM HERE");
      let bg_start = new Date(data.start_date);
      bg_start = bg_start.toISOString();
      console.log("bg_start", bg_start);

      let bg_end = new Date(data.end_date);
      bg_end = bg_end.toISOString();

      // Parse date strings using Moment.js
      const date1 = moment.utc(bg_start);
      const date2 = moment.utc(bg_end);
      let today = new Date();
      today = moment.utc(today);
      // Compare day, month, and year
      if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
        console.log("HERE");
        if (date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')) {
          bg_end = new Date();
          bg_end = bg_end.toISOString();
        } else {
          bg_end = convertLastDates(bg_end);
        }

      }
      // else {
      //   console.log("HERE ELSE", bg_end);
      //   bg_end= bg_end.toISOString();
      //
      // }

      bg_start = convertDates(data.start_date);
      bg_start = await encrypt(bg_start);

      bg_end = await encrypt(bg_end);
      dayDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
      let encType = 1;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      console.log("Here AS WELL");
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);

    }
    let receivedData = res.data.data;
    receivedData = await decrypt(receivedData);
    receivedData = JSON.parse(receivedData);
    console.log("Received Data :", receivedData);



    if (data.type === 4) {
      if (receivedData.overlay && receivedData.overlay.length > 0) {
        let len = receivedData.overlay.length;
        let mbg = calculateMbg(receivedData);
        let cv;
        let lage;
        let jIndex;
        let bgVal = [];

        let mage;

        dispatch({ type: SAVE_MBG_SUCCESS, payLoad: mbg });





        const averageBloodGlucose = mbg; // Assuming mbg is the average blood glucose value
        const hba1c = calculateHbA1c(averageBloodGlucose);
        dispatch({ type: SAVE_HBA1C_SUCCESS, payLoad: hba1c });

        // const eHbA1c = (10.929 * mbg) - 38.46;;
        // // console.log("eHbA1c (mmol/mol):", eHbA1c.toFixed(2));
        // const eHbA1cPercent = ((eHbA1c + 46.7) / 28.7)* 100;
        // // console.log("eHbA1cPercent", eHbA1cPercent);



        // function calculateEHbA1cMmol(averageBloodGlucoseMmol) {
        //   return (averageBloodGlucoseMmol + 46.7) / 28.7;
        // }

        // function calculateEHbA1cPercentageFromMmol(eHbA1cMmol) {
        //   const eHbA1cPercentage = (eHbA1cMmol * 10) + 2;
        //   return eHbA1cPercentage;
        // }

        // const averageBloodGlucoseMmol = 8.96;
        // const eHbA1cMmol = calculateEHbA1cMmol(averageBloodGlucoseMmol);
        // const eHbA1cPercentage = calculateEHbA1cPercentageFromMmol(eHbA1cMmol);

        // // console.log("eHbA1c (mmol/mol):", eHbA1cMmol.toFixed(2));
        // // console.log("eHbA1c (%):", eHbA1cPercentage.toFixed(2));
        // // HbA1c calculations end

        // MAGE calculation start
        const calculateMAGE = (bloodGlucoseReadings) => {
          if (bloodGlucoseReadings.length < 3) {
            return null; // MAGE requires at least 3 readings
          }

          let sumDifferences = 0;

          for (let i = 1; i < bloodGlucoseReadings.length - 1; i++) {
            const [prevReading, currentReading, nextReading] = [
              bloodGlucoseReadings[i - 1].value,
              bloodGlucoseReadings[i].value,
              bloodGlucoseReadings[i + 1].value,
            ];

            const peak = Math.max(prevReading, currentReading, nextReading);
            const nadir = Math.min(prevReading, currentReading, nextReading);

            sumDifferences += peak - nadir;
          }

          const mage = sumDifferences / (bloodGlucoseReadings.length - 2);
          return mage;
        }

        mage = calculateMAGE(receivedData.overlay);
        dispatch({ type: SAVE_MAGE_SUCCESS, payLoad: mage.toFixed(2) });

        // M-value

        function calculateMValue(bloodGlucoseReadings, referenceValue) {
          // Calculate the logarithmic transformation for each reading
          const logTransformedValues = bloodGlucoseReadings.map(reading => Math.log(reading));


          // Calculate the average of the logarithmic values
          const averageLogValue = logTransformedValues.reduce((sum, value) => sum + value, 0) / logTransformedValues.length;

          // Calculate the logarithmic transformation of the reference value
          const logReferenceValue = Math.log(referenceValue);

          // Calculate and return the M-Value
          const mValue = averageLogValue - logReferenceValue;
          return mValue;
        }

        const referenceValue = 18; // Reference blood glucose value

        // Extract blood glucose values from the array
        const readings = receivedData.overlay.map(reading => reading.value);

        // Calculate the sum of absolute differences of logarithmic transformations
        const sumOfDifferences = readings.reduce((sum, value) => sum + Math.abs(Math.log(value) - Math.log(referenceValue)), 0);

        // Calculate the M-Value
        const mValue = sumOfDifferences / readings.length;

        // // console.log("M-Value:", mValue.toFixed(2));
        dispatch({ type: SAVE_MVALUE_SUCCESS, payLoad: mValue.toFixed(2) });
        // M-vlue end



        // IQR Calcuations start
        const calculateIQR = (data) => {
          // Extract blood glucose values from the dataset
          const bloodGlucoseValues = data.map(entry => entry.value);

          // Sort the values in ascending order
          bloodGlucoseValues.sort((a, b) => a - b);

          // Calculate the first quartile (Q1) and third quartile (Q3)
          const n = bloodGlucoseValues.length;
          const q1Index = Math.floor(n * 0.25);
          const q3Index = Math.floor(n * 0.75);
          const q1 = bloodGlucoseValues[q1Index];
          const q3 = bloodGlucoseValues[q3Index];

          // Calculate the Interquartile Range (IQR)
          const iqr = q3 - q1;

          return iqr;
        }

        // Example dataset


        const iqr = calculateIQR(receivedData.overlay);
        // console.log("Interquartile Range (mmol/L):", iqr.toFixed(2));

        dispatch({ type: SAVE_IQR_SUCCESS, payLoad: iqr.toFixed(2) });

        // IQR Calculaitons End


        // Area Above Curve (AAC) start
        // const calculateAAC=(data, threshold)=> {
        //   let aac = 0;
        //   for (let i = 0; i < data.length; i++) {
        //     if (data[i].value > threshold) {
        //       aac += data[i].value - threshold;
        //     }
        //   }
        //   return aac;
        // }

        // new aac
        const calculateAAC = (n, times, glucoseConcentrations, T) => {
          let AAC = 0;
          for (let i = 1; i < n; i++) {
            const ti = times[i];
            const tiPrev = times[i - 1];
            const Gi = glucoseConcentrations[i];
            const GiPrev = glucoseConcentrations[i - 1];

            AAC += (ti - tiPrev) * (Gi + GiPrev - 2 * T) / 2;
          }
          return AAC;
        }



        const threshold = 5.6; // mmol/L
        const glucoseConcentrations = receivedData.overlay.map(entry => entry.value);

        // Assuming `times` and `T` are defined appropriately
        const timesAAC = receivedData.overlay.map(entry => new Date(entry.time).getTime());
        const timesLength = timesAAC.length;
        const aac = calculateAAC(timesLength, timesAAC, glucoseConcentrations, threshold);
        console.log("Area Above the Curve (AAC) for", threshold, "mmol/L:", aac.toFixed(2));

        dispatch({ type: SAVE_AAC_SUCCESS, payLoad: aac.toFixed(2) });

        // Area Above Curve (AAC) end

        // Area Under the Curve start
        // const calculateAUC=(data, threshold)=> {
        //   let auc = 0;
        //   for (let i = 0; i < data.length; i++) {
        //     if (data[i].value <= threshold) {
        //       auc += threshold - data[i].value;
        //     }
        //   }
        //   return auc;
        // }

        // new AUC


        const calculateAUC = (times, glucoseReadings) => {
          if (times.length !== glucoseReadings.length || times.length < 2) {
            throw new Error('Invalid input');
          }

          // Combine timestamps and glucose readings into an array of objects
          const data = times.map((time, index) => ({ time, glucose: glucoseReadings[index] }));

          // Sort data based on timestamps
          data.sort((a, b) => Date.parse(a.time) - Date.parse(b.time));

          let auc = 0;
          for (let i = 1; i < data.length; i++) {
            const time1 = Date.parse(data[i - 1].time);
            const time2 = Date.parse(data[i].time);
            const dt = Math.abs(time2 - time1); // Use absolute difference
            const avgGlucose = (data[i].glucose + data[i - 1].glucose) / 2;
            auc += dt * avgGlucose;
          }
          return auc / 2;
        }



        const thresholdAuc = 5.6; // mmol/L
        const times = receivedData.overlay.map((time) => { return time.time });
        const readingsValue = receivedData.overlay.map((value) => { return value.value });
        const auc = calculateAUC(times, readingsValue);
        // console.log("Area Under the Curve (AUC) for", thresholdAuc, "mmol/L:", auc.toFixed(2));
        dispatch({ type: SAVE_AUC_SUCCESS, payLoad: auc.toFixed(2) });
        // Area Under the Curve end


        // High Blood Glucose Index With Threshold start
        const thresholdHbgi = 10.1; // Threshold value for high blood glucose (mmol/L)

        // Calculate HBGI for each data point
        const hbgiValues = receivedData.overlay.map(dataPoint => {
          const bg = dataPoint.value;
          const hbgi = Math.max(0, bg - thresholdHbgi);
          return hbgi;
        });

        // Calculate the average HBGI
        const averageHbgi = hbgiValues.reduce((sum, hbgi) => sum + hbgi, 0) / hbgiValues.length;

        // console.log("HBGI values:", hbgiValues);
        // console.log("Average HBGI:", averageHbgi);
        dispatch({ type: SAVE_HBGI_SUCCESS, payLoad: averageHbgi.toFixed(2) });
        // High Blood Glucose Index With Threshold End

        //Average Daily Risk Range Start
        const hypoglycemiaThreshold = 3.9; // 70 mmol/L or 3.9 mmol/L
        const hyperglycemiaThreshold = 4.2; // 180 mmol/L or 10 mmol/L

        let hypoglycemiaSum = 0;
        let hypoglycemiaCount = 0;
        let hyperglycemiaSum = 0;
        let hyperglycemiaCount = 0;

        for (const reading of receivedData.overlay) {
          if (reading.value < hypoglycemiaThreshold) {
            hypoglycemiaSum += hypoglycemiaThreshold - reading.value;
            hypoglycemiaCount++;
          }
          if (reading.value > hyperglycemiaThreshold) {
            hyperglycemiaSum += reading.value - hyperglycemiaThreshold;
            hyperglycemiaCount++;
          }
        }

        const avgHypoglycemiaADRR = hypoglycemiaCount > 0 ? hypoglycemiaSum / hypoglycemiaCount : 0;
        const avgHyperglycemiaADRR = hyperglycemiaCount > 0 ? hyperglycemiaSum / hyperglycemiaCount : 0;

        const overallADRR = avgHypoglycemiaADRR + avgHyperglycemiaADRR;

        // console.log("Average Hypoglycemia ADRR:", avgHypoglycemiaADRR);
        // console.log("Average Hyperglycemia ADRR:", avgHyperglycemiaADRR);
        // console.log("Overall ADRR:", overallADRR);
        dispatch({ type: SAVE_ADRR_SUCCESS, payLoad: overallADRR.toFixed(2) });
        //Average Daily Risk Range End

        // standard Deviation start
        const standardDev = calculateStandardDeviation(receivedData, 4);


        dispatch({ type: SAVE_SDBG_SUCCESS, payLoad: standardDev });
        cv = standardDev / mbg;
        // console.log("CV :", cv);
        cv = cv * 100;
        cv = cv.toFixed(2);
        dispatch({ type: SAVE_CV_SUCCESS, payLoad: cv });
        let maxBgVal = Math.max(...bgVal);
        let minBgVal = Math.min(...bgVal);
        lage = maxBgVal - minBgVal;
        lage = lage.toFixed(2);
        dispatch({ type: SAVE_LAGE_SUCCESS, payLoad: lage });
        let mbgsdbg = parseInt(mbg) + parseInt(standardDev);
        // // console.log(" mbsdbg", mbgsdbg);
        jIndex = 0.324 * Math.pow(mbgsdbg, 2);
        jIndex = jIndex.toFixed(2);
        dispatch({ type: SAVE_JINDEX_SUCCESS, payLoad: jIndex });
        dispatch(fetchCgmDataForHistory(receivedData.overlay, dayDif));
        let ogpReading = makeAGPReadings(receivedData.overlay);
        // console.log("ogpReading", ogpReading);
        dispatch({ type: SAVE_OGP_SUCCESS, payLoad: ogpReading });

      }

    } else {
      console.log("Received Data  ELSE:", receivedData);
      dispatch({ type: FETCH_BG_SUCCESS, payLoad: receivedData });
      const hba1c = calculateHbA1c(receivedData.averageGlucose);
      dispatch({ type: SAVE_HBA1C_SUCCESS, payLoad: hba1c });
      const stdDev = calculateStandardDeviation(receivedData, 1);
      dispatch({ type: SAVE_SDBG_SUCCESS, payLoad: stdDev });

      const avgDev = calculateAverageDeviation(receivedData.historicalReadings);
      dispatch({ type: SAVE_ADBG_SUCCESS, payLoad: avgDev });


    }
    // dispatch({ type: FETCH_BG_SUCCESS, payLoad: receivedData });
    // console.log("GIFT", receivedData);

    return receivedData;
  } catch (error) {
    // // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_BG_FAILED,
    });
  }
};

export const fetchSensorData = () => async (dispatch) => {
  dispatch({
    type: FETCH_SENSOR_START,
  });
  try {
    // // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${getSensorDetails}`);
    let receivedData = res.data.data;
    receivedData = await decrypt(receivedData);
    receivedData = JSON.parse(receivedData);
    dispatch({ type: FETCH_SENSOR_SUCCESS, payLoad: receivedData });
    return receivedData;
  } catch (error) {
    dispatch({
      type: FETCH_SENSOR_FAILED,
    });
  }
}


export const fetchCarbohydratesData = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_CARBS_START,
  });
  try {
    // // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${carbsUrl}`);
    let receivedData = res.data.data;
    // console.log("receivedData", receivedData);
    // receivedData = decrypt(receivedData);
    // receivedData = JSON.parse(receivedData);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_CARBS_SUCCESS, payLoad: receivedData });
    return res;
  } catch (error) {
    // // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_CARBS_FAILED,
    });
  }
}

export const fetchInslinData = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_INSULIN_START,
  });
  try {
    // // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${insulinUrl}`);
    let receivedData = res.data.data;
    // console.log("receivedData", receivedData);
    // receivedData = decrypt(receivedData);
    // receivedData = JSON.parse(receivedData);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_INSULIN_SUCCESS, payLoad: receivedData });
    return res;
  } catch (error) {
    // // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_INSULIN_FAILED,
    });
  }
}

export const fetchActivityData = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_ACTIVITY_START,
  });
  try {
    // // // console.log("Data",data);
    let res = await http.get(`${API_BASE_URL}${activityUrl}`);
    let receivedData = res.data.data;
    // console.log("receivedData", receivedData);
    // receivedData = decrypt(receivedData);
    // receivedData = JSON.parse(receivedData);

    // updateUnit(unit, res.data.data)
    dispatch({ type: FETCH_ACTIVITY_SUCCESS, payLoad: receivedData });
    return res;
  } catch (error) {
    // // // console.log("ERRROR", error);
    dispatch({
      type: FETCH_ACTIVITY_FAILED,
    });
  }
}


const findMinMax = (arr, key) => {
  const datas = arr.map((node) => node[key]);
  return {
    min: Math.min(...datas),
    max: Math.max(...datas),
  }
}



const fetchCgmDataForHistory = (response, daysDif) => async (dispatch) => {
  console.log(daysDif);
  let dDifference = daysDif.replace(/[^A-Z\d\s]/g, "");



  // let cgm_start = data.timeFrom;
  let cgm_start = findStartDate(daysDif);


  const res = response;
  let weeksArr = [];



  let data = response;
  let firstWeekStartDate = moment(cgm_start);

  // let numberOfWeeks = Math.ceil(daysDif / 1);
  let numberOfWeeks = dDifference;

  let weekStartDate = "";
  let weekEndDate = "";
  for (let i = 0; i < numberOfWeeks; i++) {
    if (i === 0) {
      weekStartDate = firstWeekStartDate;
      weekEndDate = moment(firstWeekStartDate).add(24, 'hours');
    }
    let week = [];
    if (data && data.length > 0) {
      let weekSD = weekStartDate;
      let weekED = weekEndDate;

      data.forEach((day, index) => {
        let p1 = moment(weekSD);

        let p2 = moment(day.time);

        let p3 = moment(weekED);


        if (p2.isAfter(p1) && p2.isBefore(p3)) {
          // // // // console.log("READING TIME", p2);
          let timeUTC = day.time;
          // // // console.log("day.time", day.time);
          // let converted = moment(timeUTC).utc();
          // // // // console.log("CHECK", moment(converted).tz.guess());

          // const gue =  moment.tz(timeUTC, "UTC");
          const gue = moment(timeUTC);
          // // // console.log("gue", gue);
          let conTime = moment(gue._d).format("HH:mm");
          // // // console.log("conTime",conTime);

          let obj = { readingType: day.readingType, time: day.time, glucose: day.value, takenTime: day.takenTime, userPId: day.userPId, _id: day._id }
          week.push(obj);
        }
      })
    } else {
    }
    let obj = {
      weekStartDate: new Date(weekStartDate).toString(),
      readings: [week]
    }
    weeksArr.push(obj);
    // weekStartDate= moment(weekEndDate).add(1, 'days');
    // weekEndDate = moment(weekEndDate).add(7, 'days');
    weekStartDate = moment(weekEndDate)
    weekEndDate = moment(weekEndDate).add(24, 'hours');
  };




  // let receivedData = res.data;

  dispatch({ type: FETCH_HISTORY_CGM_RECORD_SUCCESS, payLoad: weeksArr });
  // // console.log("WEEK ARRAY", weeksArr);
  return weeksArr;

};

// New Version
export const findStartDate = async (filter) => {
  // console.log("filter", filter);

  const filters = {
    "30days": { subtract: { days: 29 }, dif: 30 },
    "30 days": { subtract: { days: 29 }, dif: 30 },
    "7days": { subtract: { days: 6 }, dif: 7 },
    "7 days": { subtract: { days: 6 }, dif: 7 },
    "15days": { subtract: { days: 14 }, dif: 15 },
    "15 days": { subtract: { days: 14 }, dif: 15 },
    "90days": { subtract: { days: 89 }, dif: 90 },
    "90 days": { subtract: { days: 89 }, dif: 90 },
    "24hours": { add: { hours: -24 }, dif: 1 },
    "12hours": { add: { hours: -12 }, dif: 0.5 },
    "6hours": { add: { hours: -6 }, dif: 0.25 },
  };

  if (filter === "today") {
    return { date: new Date(), dif: 1 };
  }

  const selectedFilter = filters[filter];

  if (selectedFilter) {
    let start;
    if (selectedFilter.subtract) {
      start = moment().subtract(selectedFilter.subtract);
    } else if (selectedFilter.add) {
      start = moment().add(selectedFilter.add);
    }

    return { date: start.toDate(), dif: selectedFilter.dif };
  }

  return null; // Return null if no valid filter is matched
};


// Old Version
// export const findStartDate = async (filter) => {
//   console.log("filter", filter);

//   if (filter === "30days" || filter === "30 days") {
//     let start = moment().subtract(29, 'days');


//     return { date: new Date(start._d), dif: 30 };

//   } else if (filter === "7days" || filter === "7 days") {

//     let start = moment().subtract(6, 'days');


//     return { date: new Date(start._d), dif: 7 };


//   } else if (filter === "15days" || filter === "15 days") {


//     let start = moment().subtract(14, 'days');

//     return { date: new Date(start._d), dif: 15 };

//   } else if (filter === "90days" || filter === "90 days") {

//     let start = moment().subtract(89, 'days');


//     return { date: new Date(start._d), dif: 90 };

//   } else if (filter === "24hours") {

//     let start = moment().add(-24, 'hours');


//     return { date: new Date(start._d), dif: 30 };

//   }
//   else if (filter === "12hours") {
//     let start = moment().add(-12, 'hours');


//     return { date: new Date(start._d), dif: 30 };
//   }
//   else if (filter === "6hours") {
//     let start = moment().add(-6, 'hours');


//     return { date: new Date(start._d), dif: 30 };
//   } else if (filter === "today") {

//     return new Date();

//   } else {

//   }

// }


//   const makeAGPReadings= (data)=>{
//   let eachHourData=[];
//   let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
//
//     for(let i =0;i<24; i++){
//       let obj = data.filter((dayInner, index)=>{
//       return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])
//
//     });
//       obj.sort((a, b)=>{return a.value-b.value});
//       eachHourData.push(obj);
//     }
//   return eachHourData;
// }

const makeAGPReadings = (data) => {
  const timeSlots = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));

  const eachHourData = timeSlots.map((slot) => {
    const filteredData = data.filter((item) => {
      const hour = parseInt(moment(item.time).format("HH"));
      return hour === parseInt(slot);
    });

    filteredData.sort((a, b) => a.value - b.value);
    return filteredData;
  });

  return eachHourData;
};
export const convertDates = (date) => {
  let conDate = new Date(date);
  conDate = conDate.setHours(0);
  conDate = new Date(conDate);
  conDate = conDate.toISOString();
  // console.log("CONDATE", conDate);
  return conDate;

}

export const convertLastDates = (date) => {
  let conDate = new Date(date);
  conDate = conDate.setHours(0);
  conDate = new Date(conDate);
  // console.log("hours", conDate.getHours());
  conDate = moment(conDate).add({ hours: 23, minutes: 59 });
  conDate = conDate.toISOString();
  // console.log("CONDATE", conDate);
  return conDate;

}

export const fetchBgDataForCsv = (data) => async (dispatch) => {
  console.log("DATA :", data);
  // dispatch({
  //   // type: FETCH_BG_START,
  // });
  try {
    let bgTime;
    let res;
    let dayDif;
    let type = data && data.type === 4 ? data.type : 1;
    if (data.time) {
      bgTime = data.time.replace(/ /g, '');
      dayDif = data.time;
      let calculatedStartDate = await findStartDate(data.time);
      let bg_start = calculatedStartDate.date;
      bg_start = bg_start.toISOString();
      bg_start = await encrypt(bg_start);
      let bg_end = new Date();
      bg_end = bg_end.toISOString();
      bg_end = await encrypt(bg_end);
      let encType = type;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);
    } else if (data.start_date) {
      console.log("IM HERE");
      let bg_start = new Date(data.start_date);
      bg_start = bg_start.toISOString();
      console.log("bg_start", bg_start);

      let bg_end = new Date(data.end_date);
      bg_end = bg_end.toISOString();

      // Parse date strings using Moment.js
      const date1 = moment.utc(bg_start);
      const date2 = moment.utc(bg_end);
      let today = new Date();
      today = moment.utc(today);
      // Compare day, month, and year
      if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
        // console.log("HERE");
        if (date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')) {
          bg_end = new Date();
          bg_end = bg_end.toISOString();
        } else {
          bg_end = convertLastDates(bg_end);
        }

      }
     

      bg_start = convertDates(data.start_date);
      bg_start = await encrypt(bg_start);

      bg_end = await encrypt(bg_end);
      dayDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
      let encType = 1;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      console.log("Here AS WELL");
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);

    }
    let transmitter = await dispatch(fetchSensorData());
    console.log("transmitter", transmitter);
    let receivedData = res.data.data;
    receivedData = await decrypt(receivedData);
    receivedData = JSON.parse(receivedData);
    console.log("Received Data :", receivedData);

    // dispatch({ type: FETCH_BG_SUCCESS, payLoad: receivedData });
    // console.log("GIFT", receivedData);

    return receivedData;
  } catch (error) {
    // // // console.log("ERRROR", error);
    dispatch({
      // type: FETCH_BG_FAILED,
    });
  }
};

// const  calculateLBGI=(cgmData, threshold)=> {
//   let lbgi = 0;
//
//   let totalHypoglycemicTime = 0;
//
//   for (let i = 1; i < cgmData.length; i++) {
//     const glucose = cgmData[i].glucose;
//     const deltaTime = (cgmData[i].time - cgmData[i - 1].time) / 60; // Convert minutes to hours
//
//     if (glucose < threshold) {
//       totalHypoglycemicTime += deltaTime;
//     }
//   }
//
//   // Calculate LBGI based on the percentage of time spent in the hypoglycemic range
//   if (totalHypoglycemicTime > 0) {
//     lbgi = 100 * Math.sqrt(totalHypoglycemicTime);
//   }
//
//   return lbgi;
// }
//
// // Example CGM data
// const cgmData = [
//   { time: 0, glucose: 100 },
//   { time: 10, glucose: 90 },
//   { time: 20, glucose: 85 },
//   { time: 30, glucose: 110 },
//   { time: 40, glucose: 120 },
//   { time: 50, glucose: 130 },
//   { time: 60, glucose: 140 },
// ];
//
// // Example usage
// const threshold = 70; // Adjust the threshold as needed
// const lbgiResult = calculateLBGI(cgmData, threshold);
// console.log('LBGI:', lbgiResult);
//
//
// const glucoseValues = [100, 90, 85, 110, 120, 130, 140];
// // const threshold = 70;
//
// const calculatePDF=(data, threshold)=> {
//   // Create a histogram
//   const histogram = data.reduce((hist, value) => {
//     const bin = Math.floor(value / 10) * 10; // Adjust bin size as needed
//     hist[bin] = (hist[bin] || 0) + 1;
//     return hist;
//   }, {});
//
//   // Calculate PDF by normalizing the histogram
//   const totalValues = data.length;
//   const pdf = Object.keys(histogram).reduce((pdf, bin) => {
//     const probability = histogram[bin] / totalValues;
//     pdf[bin] = probability;
//     return pdf;
//   }, {});
//
//   return pdf;
// }
//
// // Example usage
// const pdfResult = calculatePDF(glucoseValues, threshold);
// console.log('PDF:', pdfResult);
//
//
//
// ////
//
//
//
// /**
//  * Calculate Low Blood Glucose Index (LBGI).
//  * @param {number[]} glucoseValues - An array of blood glucose measurements.
//  * @returns {number} - The LBGI value.
//  */
const calculateLBGI2 = (glucoseValues) => {
  const n = glucoseValues.length;
  let lbgiSum = 0;

  for (const glucose of glucoseValues) {
    // Calculate fbg_i using the LBGI formula
    const fbg_i = Math.min(0, 1.509 * (Math.log(glucose) ** 1.084 - 5.381));
    lbgiSum += 10 * fbg_i ** 2;
  }

  // Calculate LBGI
  const lbgi = lbgiSum / n;
  return lbgi;
}

// Example usage:


let testData = [
  {
    "time": "2022-08-22 13:39:30",
    "glucose": 10.1
  },
  {
    "time": "2022-08-22 13:44:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 13:49:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 13:54:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 13:59:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 14:04:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 14:09:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 14:14:30",
    "glucose": 10.4
  },
  {
    "time": "2022-08-22 14:14:30",
    "glucose": 10.4
  },
  {
    "time": "2022-08-22 14:19:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 14:24:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 14:29:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 14:34:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 14:39:30",
    "glucose": 10.5
  },
  {
    "time": "2022-08-22 14:44:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 14:49:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 14:54:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 14:59:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 15:04:30",
    "glucose": 9.9
  },
  {
    "time": "2022-08-22 15:09:30",
    "glucose": 9.6
  },
  {
    "time": "2022-08-22 15:14:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 15:19:30",
    "glucose": 8.9
  },
  {
    "time": "2022-08-22 15:24:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 15:29:30",
    "glucose": 9.5
  },
  {
    "time": "2022-08-22 16:44:30",
    "glucose": 8.5
  },
  {
    "time": "2022-08-22 16:49:30",
    "glucose": 8.5
  },
  {
    "time": "2022-08-22 16:54:30",
    "glucose": 8.5
  },
  {
    "time": "2022-08-22 16:59:30",
    "glucose": 8.4
  },
  {
    "time": "2022-08-22 17:04:30",
    "glucose": 8.2
  },
  {
    "time": "2022-08-22 17:09:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 17:14:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 17:19:30",
    "glucose": 7.9
  },
  {
    "time": "2022-08-22 17:24:30",
    "glucose": 7.6
  },
  {
    "time": "2022-08-22 17:29:30",
    "glucose": 7.5
  },
  {
    "time": "2022-08-22 17:29:30",
    "glucose": 7.5
  },
  {
    "time": "2022-08-22 17:34:30",
    "glucose": 7.5
  },
  {
    "time": "2022-08-22 17:39:30",
    "glucose": 7.4
  },
  {
    "time": "2022-08-22 17:44:30",
    "glucose": 7.3
  },
  {
    "time": "2022-08-22 17:49:30",
    "glucose": 7.2
  },
  {
    "time": "2022-08-22 17:54:30",
    "glucose": 7.2
  },
  {
    "time": "2022-08-22 17:59:30",
    "glucose": 6.9
  },
  {
    "time": "2022-08-22 18:04:30",
    "glucose": 6.4
  },
  {
    "time": "2022-08-22 18:09:30",
    "glucose": 6.9
  },
  {
    "time": "2022-08-22 18:14:30",
    "glucose": 7.3
  },
  {
    "time": "2022-08-22 18:14:30",
    "glucose": 7.3
  },
  {
    "time": "2022-08-22 18:19:30",
    "glucose": 7.2
  },
  {
    "time": "2022-08-22 18:24:30",
    "glucose": 7.2
  },
  {
    "time": "2022-08-22 18:29:30",
    "glucose": 7.1
  },
  {
    "time": "2022-08-22 18:34:30",
    "glucose": 7.1
  },
  {
    "time": "2022-08-22 18:39:30",
    "glucose": 7.1
  },
  {
    "time": "2022-08-22 18:44:30",
    "glucose": 7.3
  },
  {
    "time": "2022-08-22 18:49:30",
    "glucose": 7.5
  },
  {
    "time": "2022-08-22 18:54:30",
    "glucose": 7.8
  },
  {
    "time": "2022-08-22 18:59:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 19:04:30",
    "glucose": 8.5
  },
  {
    "time": "2022-08-22 19:09:30",
    "glucose": 9.1
  },
  {
    "time": "2022-08-22 19:14:30",
    "glucose": 9.5
  },
  {
    "time": "2022-08-22 19:19:30",
    "glucose": 9.7
  },
  {
    "time": "2022-08-22 19:24:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 19:29:30",
    "glucose": 10.5
  },
  {
    "time": "2022-08-22 19:34:30",
    "glucose": 10.9
  },
  {
    "time": "2022-08-22 19:39:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 19:44:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 19:49:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 19:54:30",
    "glucose": 10.8
  },
  {
    "time": "2022-08-22 19:59:30",
    "glucose": 10.8
  },
  {
    "time": "2022-08-22 20:04:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 20:09:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 20:14:30",
    "glucose": 10.8
  },
  {
    "time": "2022-08-22 20:19:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 20:24:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 20:29:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 20:34:30",
    "glucose": 10.3
  },
  {
    "time": "2022-08-22 20:39:30",
    "glucose": 10.1
  },
  {
    "time": "2022-08-22 20:44:30",
    "glucose": 10
  },
  {
    "time": "2022-08-22 20:49:30",
    "glucose": 10
  },
  {
    "time": "2022-08-22 20:54:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 20:59:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 21:04:30",
    "glucose": 10.2
  },
  {
    "time": "2022-08-22 21:09:30",
    "glucose": 10.4
  },
  {
    "time": "2022-08-22 21:14:30",
    "glucose": 10.7
  },
  {
    "time": "2022-08-22 21:19:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 21:24:30",
    "glucose": 10.6
  },
  {
    "time": "2022-08-22 21:29:30",
    "glucose": 10.4
  },
  {
    "time": "2022-08-22 21:34:30",
    "glucose": 10.1
  },
  {
    "time": "2022-08-22 21:39:30",
    "glucose": 10
  },
  {
    "time": "2022-08-22 21:44:30",
    "glucose": 9.8
  },
  {
    "time": "2022-08-22 21:49:30",
    "glucose": 9.5
  },
  {
    "time": "2022-08-22 21:54:30",
    "glucose": 9.8
  },
  {
    "time": "2022-08-22 21:59:30",
    "glucose": 9.6
  },
  {
    "time": "2022-08-22 22:04:30",
    "glucose": 9.4
  },
  {
    "time": "2022-08-22 22:09:30",
    "glucose": 9.1
  },
  {
    "time": "2022-08-22 22:14:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 22:19:30",
    "glucose": 9.5
  },
  {
    "time": "2022-08-22 22:24:30",
    "glucose": 9.5
  },
  {
    "time": "2022-08-22 22:29:30",
    "glucose": 9.6
  },
  {
    "time": "2022-08-22 22:34:30",
    "glucose": 9.6
  },
  {
    "time": "2022-08-22 22:39:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 22:44:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 22:49:30",
    "glucose": 9.2
  },
  {
    "time": "2022-08-22 22:54:30",
    "glucose": 9.3
  },
  {
    "time": "2022-08-22 22:59:30",
    "glucose": 8.9
  },
  {
    "time": "2022-08-22 23:04:30",
    "glucose": 8.6
  },
  {
    "time": "2022-08-22 23:09:30",
    "glucose": 8.3
  },
  {
    "time": "2022-08-22 23:14:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 23:19:30",
    "glucose": 7.8
  },
  {
    "time": "2022-08-22 23:24:30",
    "glucose": 7.6
  },
  {
    "time": "2022-08-22 23:29:30",
    "glucose": 7.5
  },
  {
    "time": "2022-08-22 23:34:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 23:39:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 23:44:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 23:49:30",
    "glucose": 8.1
  },
  {
    "time": "2022-08-22 23:54:30",
    "glucose": 8.2
  },
  {
    "time": "2022-08-22 23:59:30",
    "glucose": 8.2
  }
];
// const glucoseData = [80, 90, 85, 50, 120, 130, 140]; // Replace with actual glucose values
const glucoseData = testData.map((record) => { return (record.glucose) * 18.0182 }); // Replace with actual glucose values
// console.log("glucoseData", glucoseData);
const lbgiValue = calculateLBGI2(glucoseData);
// console.log(`LBGI value: ${lbgiValue.toFixed(4)}`);






const calculateLBGI3 = (glucoseValues) => {
  const n = glucoseValues.length;
  let lbgiSum = 0;

  for (const glucose of glucoseValues) {
    // Convert glucose to mmol/L for LBGI calculation
    const glucoseMgdl = glucose * 18.0182; // Conversion factor: mmol/L to mmol/L

    // Calculate fbg_i using the LBGI formula
    const fbg_i = Math.min(0, 1.509 * (Math.log(glucoseMgdl) ** 1.084 - 5.381));
    lbgiSum += 10 * fbg_i ** 2;
  }

  // Calculate LBGI
  const lbgi = lbgiSum / n;
  return lbgi;
}

// Example usage:
const lbgiValueMmol = calculateLBGI3(glucoseData);
// console.log(`LBGI value (mmol/L): ${lbgiValueMmol.toFixed(4)}`);


// HbA1c calculations start
export const calculateHbA1c = (averageBloodGlucose) => {
  // console.log("averageBloodGlucose", averageBloodGlucose);
  let eHbA1cMmol = 0;
  // console.log("averageBloodGlucose", averageBloodGlucose);
  // console.log("averageBloodGlucose", typeof (averageBloodGlucose));
  if (averageBloodGlucose !== null && averageBloodGlucose !== undefined) {
    eHbA1cMmol = ((JSON.parse(averageBloodGlucose) + (46.7 / 18.018)) / (28.7 / 18.018));
    console.log("eHbA1cMmol", eHbA1cMmol);
    const eHbA1cPercentage = (eHbA1cMmol * 10) + 2;
    eHbA1cMmol = eHbA1cMmol.toFixed(2);

  }
  return eHbA1cMmol;
};

const calculateStandardDeviation = (data, type) => {
  let sd = [];
  let sdbg;
  let val2 = 0;
  let mbg = calculateMbg(data, type);
  if (type === 4) {
    let len = data.overlay.length;
    data.overlay.forEach((x) => {
      let unit = x.value - mbg;
      unit = Math.pow(unit, 2);
      sd.push(unit);
    });
    sd.forEach((item, i) => {
      val2 = val2 + item;
    });
    val2 = val2 / len;
    sdbg = Math.sqrt(val2);
    sdbg = sdbg.toFixed(2);
  } else {
    let len = data.historicalReadings.length;
    data.historicalReadings.forEach((reading) => {
      let unit = reading.value - mbg;
      // console.log("unit", unit);
      unit = Math.pow(unit, 2);
      // console.log("unit with square", unit);
      sd.push(unit);
    });
    sd.forEach((item, i) => {
      val2 = val2 + item;
    });
    val2 = val2 / len;
    sdbg = Math.sqrt(val2);
    sdbg = sdbg.toFixed(2);
  }
  return sdbg;
}

const calculateMbg = (data, type) => {
  let meanBloodGlucose = 0;
  let val = 0;
  if (type === 4) {
    data.overlay.forEach((x) => {
      // let bloodGlucoseVal = x.value;
      // bgVal.push(bloodGlucoseVal);
      val = val + x.value;
    });
    meanBloodGlucose = val / data.overlay.length;
    meanBloodGlucose = meanBloodGlucose.toFixed(2);
  } else {
    data.historicalReadings.forEach((reading) => {
      // let bloodGlucoseVal = reading.value;
      // bgVal.push(bloodGlucoseVal);
      val = val + reading.value;
    });
    meanBloodGlucose = val / data.historicalReadings.length;
    meanBloodGlucose = meanBloodGlucose.toFixed(2);
    // console.log("meanBloodGlucose", meanBloodGlucose);
  }
  return meanBloodGlucose;
}
export const calculateAverageDeviation = (data) => {
  // Step 1: Extract values from the data
  const values = data.map(item => item.value);

  // Step 2: Calculate the mean
  const mean = values.reduce((sum, value) => sum + value, 0) / values.length;

  // Step 3: Calculate the sum of absolute deviations
  const sumOfAbsoluteDeviations = values.reduce((sum, value) => sum + Math.abs(value - mean), 0);

  // Step 4: Calculate the average deviation
  const averageDeviation = sumOfAbsoluteDeviations / values.length;
  // console.log("averageDeviation", averageDeviation);
  return averageDeviation;
}


// export const calculateHbA1c =  (averageBloodGlucose) => {
//   console.log("averageBloodGlucose", averageBloodGlucose);
//   let eHbA1cMmol = 0;
//   console.log("averageBloodGlucose", averageBloodGlucose);
//   console.log("averageBloodGlucose", typeof (averageBloodGlucose));
//   if (averageBloodGlucose !== null && averageBloodGlucose !== undefined) {
//     eHbA1cMmol = ((JSON.parse(averageBloodGlucose) + (46.7 / 18.018)) / (28.7 / 18.018));
//     console.log("eHbA1cMmol", eHbA1cMmol);
//     const eHbA1cPercentage = (eHbA1cMmol * 10) + 2;
//     eHbA1cMmol = eHbA1cMmol.toFixed(2);
//   }
//   console.log("eHbA1cMmol", eHbA1cMmol); // Log here
//   return eHbA1cMmol;
// };