import {
    API_BASE_URL,
} from "../../../utils/urls";
import {
    FETCH_ANALYTICS_START,
    FETCH_ANALYTICS_FAILED,
    FETCH_ANALYTICS_SUCCESS,
    FETCH_ACTIVE_USERS_FAILED,
    FETCH_ACTIVE_USERS_SUCCESS,
    FETCH_ACTIVE_USERS_START,
    FETCH_TOTAL_USERS_FAILED,
    FETCH_TOTAL_USERS_SUCCESS,
    FETCH_TOTAL_USERS_START,
    FETCH_COUNTRIES_START,
    FETCH_COUNTRIES_FAILED,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_COUNTRIES_USERS_SUCCESS,
    FETCH_COUNTRIES_CITIES_SUCCESS,
    FETCH_COUNTRIES_PERCENTAGE_SUCCESS,

} from "../../actionTypes/admin";
import http from "../../../utils/http-service";

import ShowToast from "../../../utils/show-toast";
import moment from "moment";
import { encrypt, decrypt } from "../../../utils/encryption";


const getAnalyticsStart = () => {
    return { type: FETCH_ANALYTICS_START };
}
const getAnalyticsFailed = () => {
    return { type: FETCH_ANALYTICS_FAILED };
}
export const getAnalytics = (data) => async (dispatch) => {
    console.log("data", data);
    let noWhitespaceStr = "";
    let dayDif;
    dispatch(getAnalyticsStart());
    // // role = JSON.stringify(role);
    // let user = await encrypt(patient);
    // let encApp = await encrypt(app);
    try {
        if (data && data.time) {
            noWhitespaceStr = data.time.replace(/\s+/g, '');
            let requestedData = {
                "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
                "dimensions": [{ "name": "yearMonth" }],
                "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
            }
            console.log("requestedData", requestedData);
            requestedData = JSON.stringify(requestedData);
            requestedData = await encrypt(requestedData);
            const res = await http.post(`${API_BASE_URL}/admin/report`, { data: requestedData });
            console.log("res", res.data.data);

            let responseData = await decrypt(res.data.data);

            responseData = JSON.parse(responseData);

// other apis call

const country = {
    "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
    "dimensions": [{ "name": "country" }],
    "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
}

let countryCities = {
    "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
    "dimensions": [{ "name": "country" }, { "name": "city" }],
    "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
}
countryCities = JSON.stringify(countryCities);
countryCities = await encrypt(countryCities);
let devicesWise = {
    "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
    "dimensions": [{ "name": "deviceCategory" }],
    "metrics": [{ "name": "totalUsers" }]
}
devicesWise = JSON.stringify(devicesWise);
devicesWise = await encrypt(devicesWise);
let ageWise = {
    "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
    "dimensions": [{ "name": "userAgeBracket" }],
    "metrics": [{ "name": "activeUsers" }]
}
ageWise = JSON.stringify(ageWise);
ageWise = await encrypt(ageWise);




const resDevice = await http.post(`${API_BASE_URL}/admin/report`, {data:devicesWise});
const resCountryCities = await http.post(`${API_BASE_URL}/admin/report`, {data:countryCities});
const resAgeWise = await http.post(`${API_BASE_URL}/admin/report`, {data:ageWise});


console.log("resAgeWise", resAgeWise);



const cityWise = (response) => {
    const countryMap = {};

    response.rows.forEach(row => {
        const country = row.dimensionValues[0].value;
        const city = row.dimensionValues[1].value;
        const totalUsers = parseInt(row.metricValues[2].value, 10);

        if (!countryMap[country]) {
            countryMap[country] = { country, totalUsers: 0, cities: [] };
        }

        countryMap[country].cities.push({ city, users: totalUsers });
        countryMap[country].totalUsers += totalUsers;
    });

    return Object.values(countryMap);
}
let dataForCountryCityWise = resCountryCities.data.data;
dataForCountryCityWise = await decrypt(dataForCountryCityWise);
dataForCountryCityWise = JSON.parse(dataForCountryCityWise);

const countryCityWise = cityWise(dataForCountryCityWise.response);
console.log(countryCityWise);
dispatch({ type: FETCH_COUNTRIES_USERS_SUCCESS, payload: countryCityWise });


// Calculate total users across all countries
const totalUsersForPercentage = countryCityWise.reduce((acc, country) => acc + country.totalUsers, 0);

// Calculate percentage of users for each country
const countryWithPercentage = countryCityWise.map(country => {
    return {
        country: country.country,
        totalUsers: country.totalUsers,
        percentage: ((country.totalUsers / totalUsersForPercentage) * 100).toFixed(2) + '%'
    };
});

console.log("countryWithPercentage", countryWithPercentage);
dispatch({ type: FETCH_COUNTRIES_PERCENTAGE_SUCCESS, payload: countryWithPercentage });


            let totalUsers = [];
            let activeness = []
            let activeUser = [];
            // Extracting and transforming data
            responseData.response.rows.forEach(item => {
                const dateValue = item.dimensionValues[0].value;
                const formattedDate = moment(dateValue, 'YYYYMM').format('YYYYMM');
                totalUsers.push({
                    date: formattedDate,
                    users: item.metricValues[2].value
                });
                const activeUsers = parseInt(item.metricValues[0].value, 10);
                const totalMembers = parseInt(item.metricValues[2].value, 10);
                const inactiveUsers = totalMembers - activeUsers;
                activeness.push({
                    date: formattedDate,
                    active: activeUsers
                });
                activeness.push({
                    date: formattedDate,
                    inActive: inactiveUsers
                });
            });
            totalUsers = totalUsers.sort((a, b) => a.date.localeCompare(b.date)); // Sorting in ascending order by date
            dispatch({ type: FETCH_TOTAL_USERS_SUCCESS, payload: totalUsers });
            activeness = activeness.sort((a, b) => a.date.localeCompare(b.date)); // Sorting in ascending order by date
            console.log(totalUsers);
            console.log(activeness);
            const length = activeness.length;
            activeUser.push(activeness[length - 1]);
            activeUser.push(activeness[length - 2]);
            console.log("activeUser", activeUser);
            dispatch({ type: FETCH_ACTIVE_USERS_SUCCESS, payload: activeUser });

        } else if (data && data.start_date) {
            console.log("IM HERE");
            let bg_start = new Date(data.start_date);
            bg_start = bg_start.toISOString();
            console.log("bg_start", bg_start);

            let bg_end = new Date(data.end_date);
            bg_end = bg_end.toISOString();

            // Parse date strings using Moment.js
            let date1 = moment.utc(bg_start);
            let date2 = moment.utc(bg_end);
            let today = new Date();
            today = moment.utc(today);
            // Compare day, month, and year
            if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
                console.log("HERE");
                if (date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')) {
                    bg_end = new Date();
                    bg_end = bg_end.toISOString();
                } else {
                    bg_end = convertLastDates(bg_end);
                }

            }
            // else {
            //   console.log("HERE ELSE", bg_end);
            //   bg_end= bg_end.toISOString();
            //
            // }

            // bg_start = convertDates(data.start_date);
            // bg_start = await encrypt(bg_start);
            date1 = moment(date1).format("YYYY-MM-DD");
            date2 = moment(date2).format("YYYY-MM-DD");

            // bg_end = await encrypt(bg_end);
            dayDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
            // encType = JSON.stringify(encType);
            // encType = await encrypt(encType);
            console.log("Here AS WELL");
            let requestedData = {
                "dateRanges": [{ "startDate": date1, "endDate": date2 }],
                "dimensions": [{ "name": "yearMonth" }],
                "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
            }
            const country = {
                "dateRanges": [{ "startDate": date1, "endDate": date2 }],
                "dimensions": [{ "name": "country" }],
                "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
            }

            let countryCities = {
                "dateRanges": [{ "startDate": date1, "endDate": date2 }],
                "dimensions": [{ "name": "country" }, { "name": "city" }],
                "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
            }
            countryCities = JSON.stringify(countryCities);
            countryCities = await encrypt(countryCities);
            let devicesWise = {
                "dateRanges": [{ "startDate": date1, "endDate": date2 }],
                "dimensions": [{ "name": "deviceCategory" }],
                "metrics": [{ "name": "totalUsers" }]
            }
            devicesWise = JSON.stringify(devicesWise);
            devicesWise = await encrypt(devicesWise);
            let ageWise = {
                "dateRanges": [{ "startDate": date1, "endDate": date2 }],
                "dimensions": [{ "name": "userAgeBracket" }],
                "metrics": [{ "name": "activeUsers" }]
            }
            ageWise = JSON.stringify(ageWise);
            ageWise = await encrypt(ageWise);
            
            requestedData = JSON.stringify(requestedData);
            requestedData = await encrypt(requestedData);

            const res = await http.post(`${API_BASE_URL}/admin/report`, {data:requestedData});
            const resDevice = await http.post(`${API_BASE_URL}/admin/report`, {data:devicesWise});
            const resCountryCities = await http.post(`${API_BASE_URL}/admin/report`, {data:countryCities});
            const resAgeWise = await http.post(`${API_BASE_URL}/admin/report`, {data:ageWise});


            console.log("resAgeWise", resAgeWise);

            let responseData = res.data.data;
            responseData = await decrypt(responseData);
            responseData = JSON.parse(responseData);
            let totalUsers = [];
            let activeness = []
            let activeUser = [];
            // Extracting and transforming data
            responseData.response.rows.forEach(item => {
                const dateValue = item.dimensionValues[0].value;
                const formattedDate = moment(dateValue, 'YYYYMM').format('YYYYMM');
                totalUsers.push({
                    date: formattedDate,
                    users: item.metricValues[2].value
                });
                const activeUsers = parseInt(item.metricValues[0].value, 10);
                const totalMembers = parseInt(item.metricValues[2].value, 10);
                const inactiveUsers = totalMembers - activeUsers;
                activeness.push({
                    date: formattedDate,
                    active: activeUsers
                });
                activeness.push({
                    date: formattedDate,
                    inActive: inactiveUsers
                });
            });
            totalUsers = totalUsers.sort((a, b) => a.date.localeCompare(b.date)); // Sorting in ascending order by date
            dispatch({ type: FETCH_TOTAL_USERS_SUCCESS, payload: totalUsers });
            activeness = activeness.sort((a, b) => a.date.localeCompare(b.date)); // Sorting in ascending order by date
            console.log(totalUsers);
            console.log(activeness);
            const length = activeness.length;
            activeUser.push(activeness[length - 1]);
            activeUser.push(activeness[length - 2]);
            console.log("activeUser", activeUser);
            dispatch({ type: FETCH_ACTIVE_USERS_SUCCESS, payload: activeUser });

            // const transformResponseData = (response) => {
            //     return response.rows.map(row => ({
            //         country: row.dimensionValues[0].value,
            //         users: row.metricValues.find(metric => metric.oneValue === 'value').value
            //     }));
            // }

            // // Use the function to transform the response data
            // const transformedData = transformResponseData(resCountry.data.data.response);
            // console.log(transformedData);





            const cityWise = (response) => {
                const countryMap = {};

                response.rows.forEach(row => {
                    const country = row.dimensionValues[0].value;
                    const city = row.dimensionValues[1].value;
                    const totalUsers = parseInt(row.metricValues[2].value, 10);

                    if (!countryMap[country]) {
                        countryMap[country] = { country, totalUsers: 0, cities: [] };
                    }

                    countryMap[country].cities.push({ city, users: totalUsers });
                    countryMap[country].totalUsers += totalUsers;
                });

                return Object.values(countryMap);
            }
            let dataForCountryCityWise = resCountryCities.data.data;
            dataForCountryCityWise = await decrypt(dataForCountryCityWise);
            dataForCountryCityWise = JSON.parse(dataForCountryCityWise);

            const countryCityWise = cityWise(dataForCountryCityWise.response);
            console.log(countryCityWise);
            dispatch({ type: FETCH_COUNTRIES_USERS_SUCCESS, payload: countryCityWise });


            // Calculate total users across all countries
            const totalUsersForPercentage = countryCityWise.reduce((acc, country) => acc + country.totalUsers, 0);

            // Calculate percentage of users for each country
            const countryWithPercentage = countryCityWise.map(country => {
                return {
                    country: country.country,
                    totalUsers: country.totalUsers,
                    percentage: ((country.totalUsers / totalUsersForPercentage) * 100).toFixed(2) + '%'
                };
            });

            console.log("countryWithPercentage", countryWithPercentage);
            dispatch({ type: FETCH_COUNTRIES_PERCENTAGE_SUCCESS, payload: countryWithPercentage });


        }











        //   dispatch({type: FETCH_ANALYTICS_SUCCESS, payload: {totalUsers, activeness, activeUser}})
        //   let data = res.data.data;
        //   data = await decrypt(data);
        //   data = JSON.parse(data);
        //   dispatch({type: FETCH_PATIENT_SUCCESS, payload: data.users})
        // ShowToast(res.data.message,"success");
        //   return res;
    } catch (error) {
        dispatch(getAnalyticsFailed())
        ShowToast(error.message, "error");

    }
}


const convertLastDates = (date) => {
    let conDate = new Date(date);
    conDate = conDate.setHours(0);
    conDate = new Date(conDate);
    console.log("hours", conDate.getHours());

    conDate = moment(conDate).add({ hours: 23, minutes: 59 });
    // conDate = conDate.setHours(23);
    // console.log("hours", conDate.getHours());

    // conDate = conDate.setHours(conDate.getHours() + 23);
    // conDate = conDate.setMinutes(conDate.getMinutes() + 59);
    console.log("conDate after adding hours and mintues", conDate);
    conDate = conDate.toISOString();
    console.log("CONDATE", conDate);
    return conDate;

}