import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import BloodGlucoseReport from "../Reports/OverviewComps/BloodGlucoseReport";
import SensorUsageReport from "../Reports/OverviewComps/SensorUsageReport";
import TrendsBloodGlucose from "../Reports/Charts/trendsBloodGlucose";
import OverlayExpo from "../Reports/Overlay/OverlayExpo";
import TimeInRangeExpo from "../Reports/Charts/TimeInRangeExpo";
import moment from "moment";

import {decrypt} from "../../utils/encryption";

const ExportComp = (props) => {

  let user = useSelector((state) => {
    if(state.user.userDetails && state.user.userDetails.user){
      return state.user.userDetails.user;
    }});


  const [localData, setLocalData] = useState("");

  useEffect(()=>{

    handleExportData()

  },[])
  const handleExportData=async()=>{
    let data=localStorage.getItem("export")
    data=decrypt(data);
    data=JSON.parse(data);

    setLocalData(data);


  }


  useEffect(()=>{


    if(user !== null){





    }
  },[user])

 


  let localDate = moment(user&&user.dateOfBirth).utc()
      let age = localDate.format("DD-MM-YYYY ");

      let localStorageData=localStorage.getItem("export")
      localStorageData=decrypt(localStorageData);
      localStorageData=JSON.parse(localStorageData);


    let startTime,endTime,lastTime;
    let date = new Date();
  if(localStorageData && localStorageData.time!==""){

     endTime = new Date();
    endTime.setHours(23);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    if (localStorageData.time) {
        if (localStorageData.time === "90days") {
            date.setDate(date.getDate() - 90);
     
        } else if (localStorageData.time === "30days") {
            date.setDate(date.getDate() - 30);
          
        } else if (localStorageData.time === "15days") {
            date.setDate(date.getDate() - 14);
            
        } else if (localStorageData.time === "7days") {
            date.setDate(date.getDate() - 7);
            
        } else if (localStorageData.time === "today") {
            
        }
    }
  }else if (localStorageData.start && localStorageData.end) {
     startTime = new Date(localStorageData.start);
    
     lastTime = new Date(localStorageData.end);
   
}


  return ( <div className='m-auto printing-screen'>

    <div className=" shadow-lg bg-white rounded pb-2">

      <div className="mt-4">
        <div className=''>
          <div className="d-flex justify-content-center ">
            <img src={GlucoRxLogo} alt="Logo" width="30%" className="mt-4"/>
          </div>
          <div className="d-flex justify-content-between font-weight-bold px-5 my-3">
          <div className="ml-5">
            <div>
              Name: {user&&user.firstName?user.firstName: "N/A"}{user && user.lastName?user.lastName:"N/A"}
            </div>
            <div>
               Email: {user&&user.email ?user.email:"N/A"}
            </div>
            <div>
               Address: {user&&user.address?user.address:"N/A"}
            </div>
          </div>

          <div className="mr-5">
            <div>
              Age: {user&&user.dateOfBirth?moment().diff(user.dateOfBirth, 'years'):"N/A"}
            </div>
            <div>
              D.O.B: {user&&user.dateOfBirth?age:"N/A"}
            </div>
            <div>
               Phone: {user&&user.phone?user.phone:"N/A"}
            </div>
          </div>

          </div>

        </div>
        <div className='text-center font-weight-bold mt-2 mb-4'>
         { localStorageData.time!==""?<div>
         <span>

           From: {moment(date).utc().format("DD-MM-YYYY ")}

         </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <span>

           To: {moment(endTime).utc().format("DD-MM-YYYY ")}

         </span>
       </div>:
       <div>
       <span>

         From: {moment(startTime).utc().format("DD-MM-YYYY ")}

       </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       <span>

         To: {moment(lastTime).utc().format("DD-MM-YYYY ")}

       </span>
     </div>
   }
        </div>
        <div className="row mw-100 m-auto">
        {localData && localData.overview?
          <div className="col-12 overlays-data">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center bg-gray px-2 py-2 font-weight-bold text-white">Overview</h1>
              </div>
              <div className="col-12" style={{ height: "300px" }}>
              <div className="bg-white rounded shadow " style={{ height: "100%" }}>
          
                <div className='h-100 d-flex flex-column justify-content-between p-4'>

            <div className="chart-heading mt-3 loginNew-HCP-panel">
              <span className="bg-primary px-2 py-2 font-weight-bold text-white">
                Last Reading
              </span>
              <span className="bg-primary px-2 py-2 font-weight-bold text-white ml-auto">
                Average
              </span>
            </div>
            


          <div className="text-center">
            <p className=" font-blue font-size4 font-weight-bold d-inline ">8.5</p>
              <span className="font-gray font-weight-bold h4">mmol/L</span>
          </div>
          <div className="text-center">
            <p className=" font-blue font-size4 font-weight-bold d-inline ">8.5</p>
              <span className="font-gray font-weight-bold h4">mmol/L</span>
          </div>
          <div className="d-flex justify-content-between px-3 mb-4">
            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Standard <br/>Devltaion</p>
              <p className="font-size1 font-weight-bold d-inline text-danger">1.9<span><i className="fas fa-long-arrow-alt-up"></i></span></p>
              <p className="font-size-small font-weight-bold font-gray">mmol/L</p>
            </div>

            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Estimated <br/>A1C</p>
              <p className="font-size1 font-weight-bold d-inline text-success">2.6<span><i className="fas fa-long-arrow-alt-down"></i></span></p>
              <p className="font-size-small font-weight-bold font-gray">mmol/L</p>
            </div>

            <div className=" text-center">
              <p className='font-size-small font-weight-bold'>Average <br/>Devltaion</p>
              <p className="font-size1 font-weight-bold d-inline text-orange">N/A</p>
              <p className="font-size-small font-weight-bold font-gray">mmol/L</p>
            </div>
          </div>
          </div>
              </div>
              </div>
              <div className="col-12">
              <div className='h-100 d-flex flex-column justify-content-between p-4'>

                <div className="chart-heading mt-3 loginNew-HCP-panel">
                  <span className="bg-primary px-2 py-2 font-weight-bold text-white">
                    Time in Range
                  </span>
                </div>
            


              <div className="row justify-content-between px-3 mb-4">
                <div className="col-md-9 col-12 ">
                 <TimeInRangeExpo chartId={"overviewChart"}/>
                </div>

                  <div className="col-md-3 col-12 mt-md-0 mt-3">


                <div className="chart-heading text-center loginNew-HCP-panel">
                  <span className="bg-primary  py-2 font-weight-bold text-white">
                    Target Range
                  </span>
                </div>

                  <div className='mt-4 text-md-start text-center '>

                  <small className="font-blue font-weight-bold">Day:</small><br/>
                  <small className="text-black font-weight-bold ">(06:00 - 22:00)</small><br/>
                  <small className=" text-black font-weight-bold">3.9-10.0 mmol/L</small><br/>

                  <small className="font-blue font-weight-bold ">Night:</small><br/>
                  <small className="text-black font-weight-bold ">(06:00 - 22:00)</small><br/>
                  <small className="text-black font-weight-bold ">3.9-10.0 mmol/L</small><br/>
                  </div>
                  </div>
                </div>

              </div>
              </div>


              <div className="col-12" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow p-4" style={{ height: "100%" }}>
                  <SensorUsageReport />
                </div>
              </div>
              <div className=" col-12" style={{ height: "500px" }}>
                <div className="bg-white rounded shadow p-4" style={{ height: "100%" }}>
                  <BloodGlucoseReport />
                </div>
              </div>
            </div>
          </div>
        :null}
        {localData && localData.patterns?

          <div className="col-12 patterns-data">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center bg-gray px-2 py-2 font-weight-bold text-white">Patterns</h1>
              </div>

              <div className=" col-12" style={{ height: "500px" }}>
                <div className="bg-white rounded shadow p-4" style={{ height: "100%" }}>
                  <TrendsBloodGlucose />
                </div>
              </div>
            </div>
          </div>
          :null}
          {localData && localData.trends?

            <div className="col-12 trends-data">
              <div className="row">
                <div className="col-12">
                  <h1 className="text-center bg-gray px-2 py-2 font-weight-bold text-white">Trends</h1>
                </div>

                <div className=" col-12" style={{ height: "500px" }}>
                  <div className="bg-white rounded shadow p-4" style={{ height: "100%" }}>
                    <TrendsBloodGlucose />
                  </div>
                </div>
              </div>
            </div>
            :null}
          {localData && localData.overlay?

          <div className="col-12 overlays-data">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center bg-gray px-2 py-2 font-weight-bold text-white">Overlays</h1>
            </div>

            <div className=" col-12">
              <div className="bg-white rounded shadow p-4">
                <OverlayExpo />
              </div>
            </div>
          </div>
          </div>
          :null}
          {localData && localData.historyTab?

          <div className="col-12">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center bg-gray px-2 py-2 font-weight-bold text-white">History</h1>
            </div>

            <div className=" col-12">
              <div className="bg-white rounded shadow p-4">
                <OverlayExpo />
              </div>
            </div>
          </div>
          </div>
          :null}
          {localData && localData.compare?

          <div className="col-12"></div>
          :null}
          {localData && localData.statistics?

          <div className="col-12"></div>
          :null}
          {localData && localData.agp?

          <div className="col-12"></div>
          :null}

        </div>
      </div>
    </div>

    </div>
  );
};

export default ExportComp;
