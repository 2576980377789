import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {saveModus} from "../redux/actions/admin/admin";
import { default as Titles } from "../utils/document-titles";
import { Tabs } from "antd";
import UserMangement from "../Components/Admin/UsersManagement";
const { TabPane } = Tabs;
export default function AdminPage() {
  const dispatch = useDispatch();
  document.title = Titles.userManagement;
  const [selectedApp, setSelectedApp] = useState("");
  // const [eData, setEData] = useState(false);
  // const [sensorLogs, setSensorLogs] = useState(false);
  // const [showSensorLogsData, setShowSensorLogsData] = useState(false);

  // const [showEData, setShowEData] = useState(false);
  // const [analytics, setAnalytics] = useState(false);
  // const [errors, setErrors] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);


  // const handleApp =(event)=>{
  //   setSelectedApp(event.target.value);
  //   setShowEData(false);
  //   if(eData!==true && showEData === true){
  //     setEData(true);

  //   }



  //     setSelectedApp("modus");
  //     dispatch(saveModus("modus"));


  // }
  // const showData = (val)=>{

  //   setShowEData(val);
  //   setOta(false);
  //                         setManagement(false);
  //                         setNotifications(false);
  //                         setClinicalData(false);
  //                         setEData(false);
  //                         setAnalytics(false);
  //                         setErrors(false);
  //                         setSensorLogs(false);

  //                         // setShowEData(false);

  // }
  // const showSLData = (val)=>{
  //   // // // console.log("VAL", val);
  //   setShowSensorLogsData(val);
  //   setOta(false);
  //                         setManagement(false);
  //                         setNotifications(false);
  //                         setClinicalData(false);
  //                         setEData(false);
  //                         setShowEData(false);

  //                         setAnalytics(false);
  //                         setErrors(false);
  //                         setSensorLogs(false);

  //                         // setShowEData(false);

  // }

  return (
    <>
    <div className="row mw-100 m-auto">
      <div className="col-12">
      <div className="bg-white p-3 rounded-lg" style={{height:"100%",maxHeight:"89vh", overflowY:"scroll"}}>

        <UserMangement app={selectedApp}/>

      </div>
      </div>

    </div>


    </>
  );
}
