import * as actions from "../actionTypes/admin";

const INITIAL_STATE = {

  notifications: null,
  isLoading: false,

};

export default (state = INITIAL_STATE, action) => {
  // // console.log("ACTION", action);
  switch (action.type) {

    case actions.FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        isLoading: true,
      };
      break;


    case actions.FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        notifications: null,
      };

      break;

    case actions.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload,
      };
      break;

      
    default:
      return {
        ...state
      }
  }
};
