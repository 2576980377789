import React, { useState, useEffect, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { default as Titles } from "../../../utils/document-titles";
import { fetchCliniciansSessionsData, fetchCliniciansSessionsDataFailed, deleteSession, fetchCliniciansSessionsDataSuccess, getLogsBySession, fetchEngineerMeasurement, fetchEngineerPoint } from "../../../redux/actions/admin/admin";
import Table from './Table';
import moment from "moment";
import Loading from "../../Common/Loading";

const ViewCliniciansData = () => {
  document.title = Titles.sessions;
  const { userDetails } = useSelector((state) => { return state.user });
  const { cliniciansSessionsData, modus2 } = useSelector((state) => { return state.admin });
  const [isPending, startTransition] = useTransition();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { _id, subId, shortId } = useParams();
  // const [engineerData, setEngineerData] = useState();
  // const [isLoading, setIsLoading] = useState(false);
  
  const [csv, setCsv] = useState("");
  // const [sessionIdToDelete, setSessionIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  // const [engineerId, setEngineerId] = useState(localStorage.removeItem("eId"));
  const [apiLoader, setApiLoader] = useState(false);

  const [sessionsToBeDeleted, setSessionsToBeDeleted] = useState([]);
  const [showDelBtn, setShowDelBtn] = useState(false);
  const [checked, setChecked] = useState(false);






  const safari = useSelector((state) => {
    // setJson(state.admin.users);

    return state.auth.safari;
  });

  useEffect(() => {
    let idP = _id.replace(":", "");


    if (cliniciansSessionsData.length === 0) {
      fetchEngineeringData(idP);
    } else {
      dispatch(fetchCliniciansSessionsDataFailed());
      fetchEngineeringData(idP);

    }
  }, []);



  useEffect(() => {
    if (sessionsToBeDeleted.length > 0) {
      setShowDelBtn(true)

    } else {
      setShowDelBtn(false)

    }
  }, [sessionsToBeDeleted.length]);

  const addSemiColons = (noSemi) => {
    let semicolonString = "";
    for (let i = 0; i < noSemi; i++) {
      semicolonString += ";";
    }
    return semicolonString;
  }
  const semiColons = addSemiColons(22);

  const fetchEngineeringData = async (id) => {
    try {

      const ress = await dispatch(fetchCliniciansSessionsData(id));

    } catch (error) {
      let shrtId = shortId.replace(":", "")
      history(`/admin/Clinicians/:${shrtId}`);
    }

  };


  const convert = async (currentSession, _id, index) => {
    // e.preventDefault();
    let eleId = 'spinner' + index;
    let spin = document.getElementById(eleId);
    // // // console.log("Spin", spin);

    spin.classList.remove('invisible');
    let timeNow = moment(Date.now()).utc().format();
    timeNow = moment(new Date(timeNow));
    let utcDif = new Date().getTimezoneOffset();
    if (utcDif < 0) {
      utcDif = utcDif * -1;
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc", timeNow);

    } else if (utcDif > 0) {
      timeNow = moment(timeNow).subtract(utcDif, 'm');
      // // // // console.log("TIMENEW else if", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else if", timeNow);
    } else {
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW else", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else", timeNow);
    }


    let csvString =
      "Author" + ";" + currentSession.userId.email + semiColons + "\n" +
      "Device" + ";" + currentSession.deviceName + semiColons + "\n" +
      "Session_ID" + ";" + currentSession._id + semiColons + "\n" +
      "SessionStartTime" + ";" + currentSession.sessionStartTime + semiColons + "\n" +
      "SessionStatus" + ";" + currentSession.status + "\n" +
      "SessionEndTimeApp" + ";" + currentSession.sessionEndTimeApp + semiColons + "\n" +
      "SessionEndTimeDevice" + ";" + currentSession.sessionEndTimeDevice + semiColons + "\n" +
      "Flags" + ";" + currentSession.flags + semiColons + "\n" +
      "OvsRate" + ";" + currentSession.ovsRate + semiColons + "\n" +
      "IntermissionTime_ms" + ";" + currentSession.intermissionTime_ms + semiColons + "\n" +
      "FirstPointSamplingDelay_ms" + ";" + currentSession.firstPointSamplingDelay_ms + semiColons + "\n" +
      "TargetedUweAtT1" + ";" + currentSession.targetedUweAtT1 + semiColons + "\n" +
      "MeasurementPeriod_s" + ";" + currentSession.measurementPeriod_s + semiColons + "\n" +
      "NumberOfMeasurements" + ";" + currentSession.numberOfMeasurements + semiColons + "\n" +
      "WECOM_mV" + ";" + currentSession.wecom_mV + semiColons + "\n" +
      "RE_mV" + ";" + currentSession.re_mV + semiColons + "\n" +
      "Download_Time" + ";" + timeNow + semiColons + "\n" +
      "firmwareVersion" + ";" + currentSession.firmwareVersion + semiColons + "\n" + "\n" + "\n";

    const headings = {
      "E1": "",
      "MD_ID": "",
      "MD_Idx": "",
      "MD_Flags": "",
      "MD_IWE1_pA": "",
      "MD_IWE2_pA": "",
      "MD_BG": "",
      "MD_T": "",
      "MD_Bat": "",
      "E2": "",
      "PD_ID": "",
      "PD_Idx": "",
      "PD_Time": "",
      "PD_UCI1_mV": "",
      "PD_UCI2_mV": "",
      "uBAT": "",
      "uTDS": "",
      "uT": "",
      "tDisc": "",
      "cpuActiveTime": "",
      "cpuSleepTime": "",
      "E3": "",
      "PHONE_OS": "",
      "PHONE_ID": ""
    }

    let measurementArray = [];
    setApiLoader(true);
    const result = {
      status: 500
    };


    // // // // console.log("MEASUREMENT DATA", result);
    if (result.status === 200) {
      setApiLoader(false);
      spin.classList.add('invisible');

    }
    let resultCsv = result.data && result.data.data && result.data.data.csv.length > 0 ? result.data.data.csv : [];
    resultCsv.forEach((measurement, index) => {
      return measurement.pointDataIds && measurement.pointDataIds.forEach((point) => {
        measurementArray.push({
          "E1": "",
          "MD_ID": measurement._id,
          "MD_Idx": measurement.index,
          "MD_Flags": "'" + measurement.flags,
          "MD_IWE1_pA": measurement.we1Current,
          "MD_IWE2_pA": measurement.we2Current,
          "MD_BG": measurement.bloodGlucose,
          "MD_T": measurement.temperature,
          "MD_Bat": measurement.batteryLevel,
          "E2": "",
          "PD_ID": point._id,
          "PD_Idx": point.index,
          "PD_Time": point.timestamp,
          "PD_UCI1_mV": point.we1,
          "PD_UCI2_mV": point.we2,
          "uBAT": point.uBAT,
          "uTDS": point.uTDS,
          "uT": point.uT,
          "tDisc": point.tDisc,
          "cpuActiveTime": point.cpuActiveTime,
          "cpuSleepTime": point.cpuSleepTime,
          "E3": "",
          "PHONE_OS": point.phoneOS,
          "PHONE_ID": point.phoneId,

        })
      });
    });
    const fileName = moment(currentSession.sessionStartTime).utc().format("YYYYMMDD") + "_" + moment(currentSession.sessionStartTime).utc().format("HHmm") + "_" + currentSession.deviceName + "_" + "data";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += measurementArray.map((j) => Object.values(j).join(";")).join("\n");
    csvString += "\n";


    setCsv(`${csvString}`);
    // let BOM = "\uFEFF";
    let csvData = csvString;
    //
    if (safari) {
      // let csvContent = csvData;
      // csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
      download(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
      download(csvData, fileName, 'text/csv;charset=utf-8;');
      // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
  const download = (processedData, filename, type) => {
    let data = processedData;

    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }


  // delete sessions
  const forDelete = async (ids) => {
    // // // // console.log("LENGTH :",sessionsToBeDeleted.length);
    // setIsLoading(true);
    try {
      // const result = await dispatch(deleteSession(ids, modus2));
      const result = await dispatch(deleteSession(ids, modus2));

      // // // // console.log("RESULT In comp", result.status);

      let sesData = cliniciansSessionsData.sessionData;
      for (let i = 0; i < ids.length; i++) {
        sesData = sesData.filter((session) => session._id !== ids[i]
        );
      }

      // // // // console.log("id", ids);
      dispatch(fetchCliniciansSessionsDataSuccess({ sessionData: sesData }));

      setDeleteModal(false);
      // setIsLoading(false);
      setSessionsToBeDeleted([]);
      let checkboxes = document.getElementsByName('checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = false;
      }

    } catch (e) {

    }


  }

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  // session logs by sessionId






  const makeDelArray = (id) => {
    // // // console.log("ID", id);
    let sessArray = sessionsToBeDeleted;
    let sessLength = sessionsToBeDeleted.length;
    if (sessLength > 0) {
      // // // console.log("sessionsToBeDeleted", sessionsToBeDeleted.length);
      sessArray = sessArray.filter((rec) => rec !== id);
      // // // // console.log("SESSARRAY", sessArray);
      if (sessLength === sessArray.length) {
        // // // // console.log("sessLength", sessLength);
        // // // // console.log("sessArrayLength", sessArray.length);

        sessArray.push(id);
        // // // // console.log("sessArray", sessArray);
        setSessionsToBeDeleted(sessArray);
      } else {
        setSessionsToBeDeleted(sessArray);
      }
    } else {
      sessArray.push(id);
      setSessionsToBeDeleted(sessArray);
      setShowDelBtn(true);
    }
    // // // // console.log("SESSIONS TO BE DELETED", sessionsToBeDeleted);
  }

  const selectAll = () => {
    // document.getElementsByName()
    // // // console.log("CHECKING all");
    if (checked) {
      let checkboxes = document.getElementsByName('checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = false;
      }
      setSessionsToBeDeleted([]);

    } else {
      let checkboxes = document.getElementsByName('checkbox');
      for (let checkbox of checkboxes) {
        checkbox.checked = true;
        let SD = cliniciansSessionsData.sessionData;
        let idsForDelete = [];
        for (let instance of SD) {
          let obj = {
            id: instance._id
          }
          idsForDelete.push(instance._id)
        }
        // // // console.log("IDS LENGTH", idsForDelete.length);
        setSessionsToBeDeleted(idsForDelete);



      }
    }
  }

  // Convert Measurement

  const convertMeasure = async (currentSession, _id, index, sessionParm) => {
    // e.preventDefault();
    let eleId = 'spinner' + index;
    let spin = document.getElementById(eleId);
    // // // console.log("Spin", spin);

    spin.classList.remove('invisible');
    let timeNow = moment(Date.now()).utc().format();
    timeNow = moment(new Date(timeNow));
    let utcDif = new Date().getTimezoneOffset();
    if (utcDif < 0) {
      utcDif = utcDif * -1;
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc", timeNow);

    } else if (utcDif > 0) {
      timeNow = moment(timeNow).subtract(utcDif, 'm');
      // // // // console.log("TIMENEW else if", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else if", timeNow);
    } else {
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW else", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else", timeNow);
    }

    let csvString =
      "Author" + ";" + currentSession.userId.email + semiColons + "\n" +
      "Device" + ";" + currentSession.deviceName + semiColons + "\n" +
      "Session_ID" + ";" + currentSession._id + semiColons + "\n" +
      "SessionStartTime" + ";" + currentSession.sessionStartTime + semiColons + "\n" +
      "SessionStatus" + ";" + currentSession.status + "\n" +
      "SessionEndTimeApp" + ";" + currentSession.sessionEndTimeApp + semiColons + "\n" +
      "SessionEndTimeDevice" + ";" + currentSession.sessionEndTimeDevice + semiColons + "\n" +
      "Flags" + ";" + currentSession.flags + semiColons + "\n" +
      "OvsRate" + ";" + currentSession.ovsRate + semiColons + "\n" +
      "ksg" + ";" + sessionParm.ksg + semiColons + "\n" +
      "SaturationVoltage" + ";" + sessionParm.saturationVoltage + semiColons + "\n" +
      "IntermissionTime_ms" + ";" + currentSession.intermissionTime_ms + semiColons + "\n" +
      "FirstPointSamplingDelay_ms" + ";" + currentSession.firstPointSamplingDelay_ms + semiColons + "\n" +
      "TargetedUweAtT1" + ";" + currentSession.targetedUweAtT1 + semiColons + "\n" +
      "MeasurementPeriod_s" + ";" + currentSession.measurementPeriod_s + semiColons + "\n" +
      "NumberOfMeasurements" + ";" + currentSession.numberOfMeasurements + semiColons + "\n" +
      "WECOM_mV" + ";" + currentSession.wecom_mV + semiColons + "\n" +
      "RE_mV" + ";" + currentSession.re_mV + semiColons + "\n" +
      "Download_Time" + ";" + timeNow + semiColons + "\n" +
      "firmwareVersion" + ";" + currentSession.firmwareVersion + semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n";

    const headings = {
      "E1": "",
      "MD_ID": "",
      "MD_Idx": "",
      "MD_Flags": "",
      "MD_IWE1_pA": "",
      "MD_IWE2_pA": "",
      "MD_BG": "",
      "MD_CT": "",
      "MD_BT": "",
      "MD_Bat": "",


    }

    let measurementArray = [];
    setApiLoader(true);
    const result = await dispatch(fetchEngineerMeasurement(_id));

    setApiLoader(false);
    spin.classList.add('invisible');


    let resultCsv = result && result.csv && result.csv.length > 0 ? result.csv : [];
    resultCsv.forEach((measurement, index) => {
      measurementArray.push({
        "E1": "",
        "MD_ID": measurement._id,
        "MD_Idx": measurement.index,
        "MD_Flags": "'" + measurement.flags,
        "MD_IWE1_pA": measurement.we1Current,
        "MD_IWE2_pA": measurement.we2Current,
        "MD_BG": measurement.bloodGlucose,
        "MD_CT": measurement.chipTemperature,
        "MD_BT": measurement.boardTemperature,
        "MD_Bat": measurement.batteryLevel,



      })
    });
    const fileName = moment(currentSession.sessionStartTime).utc().format("YYYYMMDD") + "_" + moment(currentSession.sessionStartTime).utc().format("HHmm") + "_" + currentSession.deviceName + "_" + "mdata";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += measurementArray.map((j) => Object.values(j).join(";")).join("\n");
    csvString += "\n";


    setCsv(`${csvString}`);
    // let BOM = "\uFEFF";
    let csvData = csvString;
    // // // // // console.log("csvType", typeof (csvData));
    //
    if (safari) {
      // let csvContent = csvData;
      // csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
      downloadMeasure(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
      downloadMeasure(csvData, fileName, 'text/csv;charset=utf-8;');
      // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
  const downloadMeasure = (processedData, filename, type) => {
    let data = processedData;

    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }


  // Point DATA


  const convertPoint = async (currentSession, _id, index, sessionParm) => {
    // e.preventDefault();
    let eleId = 'spinner' + index;
    let spin = document.getElementById(eleId);
    // // // console.log("Spin", spin);

    spin.classList.remove('invisible');
    let timeNow = moment(Date.now()).utc().format();
    timeNow = moment(new Date(timeNow));
    let utcDif = new Date().getTimezoneOffset();
    if (utcDif < 0) {
      utcDif = utcDif * -1;
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc", timeNow);

    } else if (utcDif > 0) {
      timeNow = moment(timeNow).subtract(utcDif, 'm');
      // // // // console.log("TIMENEW else if", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else if", timeNow);
    } else {
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW else", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else", timeNow);
    }


    let csvString =
      "Author" + ";" + currentSession.userId.email + semiColons + "\n" +
      "Device" + ";" + currentSession.deviceName + semiColons + "\n" +
      "Session_ID" + ";" + currentSession._id + semiColons + "\n" +
      "SessionStartTime" + ";" + currentSession.sessionStartTime + semiColons + "\n" +
      "SessionStatus" + ";" + currentSession.status + "\n" +
      "SessionEndTimeApp" + ";" + currentSession.sessionEndTimeApp + semiColons + "\n" +
      "SessionEndTimeDevice" + ";" + currentSession.sessionEndTimeDevice + semiColons + "\n" +
      "Flags" + ";" + currentSession.flags + semiColons + "\n" +
      "OvsRate" + ";" + currentSession.ovsRate + semiColons + "\n" +
      "DefaultDeltaTime" + ";" + sessionParm.defaultDeltaTime + semiColons + "\n" +
      "ksg" + ";" + sessionParm.ksg + semiColons + "\n" +
      "SaturationVoltage" + ";" + sessionParm.saturationVoltage + semiColons + "\n" +
      "IntermissionTime_ms" + ";" + currentSession.intermissionTime_ms + semiColons + "\n" +
      "FirstPointSamplingDelay_ms" + ";" + currentSession.firstPointSamplingDelay_ms + semiColons + "\n" +
      "TargetedUweAtT1" + ";" + currentSession.targetedUweAtT1 + semiColons + "\n" +
      "MeasurementPeriod_s" + ";" + currentSession.measurementPeriod_s + semiColons + "\n" +
      "NumberOfMeasurements" + ";" + currentSession.numberOfMeasurements + semiColons + "\n" +
      "WECOM_mV" + ";" + currentSession.wecom_mV + semiColons + "\n" +
      "RE_mV" + ";" + currentSession.re_mV + semiColons + "\n" +
      "Download_Time" + ";" + timeNow + semiColons + "\n" +
      "firmwareVersion" + ";" + currentSession.firmwareVersion + semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n";

    const headings = {
      "E1": "",
      "PD_ID": "",
      "PD_Idx": "",
      "PD_Time": "",
      "PD_UCI1_mV": "",
      "PD_UCI2_mV": "",
      "uBAT": "",
      "uTDS": "",
      "uT": "",
      "tDisc": "",
      "cpuActiveTime": "",
      "cpuSleepTime": "",
      "E2": "",
      "PHONE_OS": "",
      "PHONE_ID": "",
      "mIndex": "",
    }

    let measurementArray = [];
    setApiLoader(true);
    const result = await dispatch(fetchEngineerPoint(_id));

    setApiLoader(false);
    spin.classList.add('invisible');


    let resultCsv = result && result.csv && result.csv.length > 0 ? result.csv : [];
    resultCsv.forEach((point, index) => {
      measurementArray.push({
        "E1": "",
        "PD_ID": point._id,
        "PD_Idx": point.index,
        "PD_Time": point.timestamp,
        "PD_UCI1_mV": point.we1,
        "PD_UCI2_mV": point.we2,
        "uBAT": point.uBAT,
        "uTDS": point.uTDS,
        "uT": point.uT,
        "tDisc": point.tDisc,
        "cpuActiveTime": point.cpuActiveTime,
        "cpuSleepTime": point.cpuSleepTime,
        "E2": "",
        "PHONE_OS": point.phoneOS,
        "PHONE_ID": point.phoneId,
        "mIndex": point.mIndex,


      })
    });
    const fileName = moment(currentSession.sessionStartTime).utc().format("YYYYMMDD") + "_" + moment(currentSession.sessionStartTime).utc().format("HHmm") + "_" + currentSession.deviceName + "_" + "pdata";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += measurementArray.map((j) => Object.values(j).join(";")).join("\n");
    csvString += "\n";


    setCsv(`${csvString}`);
    // let BOM = "\uFEFF";
    let csvData = csvString;
    // // // // // console.log("csvType", typeof (csvData));
    //
    if (safari) {
      // let csvContent = csvData;
      // csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
      downloadPoint(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
      downloadPoint(csvData, fileName, 'text/csv;charset=utf-8;');
      // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
  const downloadPoint = (processedData, filename, type) => {
    let data = processedData;

    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }


  const convertLogs = async (currentSession, _id, index, sessionParm) => {
    // console.log("currentSession", currentSession);
    // e.preventDefault();
    let eleId = 'spinner' + index;
    let spin = document.getElementById(eleId);
    // // // console.log("Spin", spin);

    spin.classList.remove('invisible');

    let timeNow = moment(Date.now()).utc().format();
    timeNow = moment(new Date(timeNow));
    let utcDif = new Date().getTimezoneOffset();
    if (utcDif < 0) {
      utcDif = utcDif * -1;
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc", timeNow);

    } else if (utcDif > 0) {
      timeNow = moment(timeNow).subtract(utcDif, 'm');
      // // // // console.log("TIMENEW else if", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else if", timeNow);
    } else {
      timeNow = moment(timeNow).add(utcDif, 'm');
      // // // // console.log("TIMENEW else", timeNow);
      timeNow = moment(timeNow).utc().format();
      // // // // console.log("TIMENEW in utc else", timeNow);
    }

    // "\uFEFF"+
    let csvString =
      "Author" + ";" + currentSession.userId.email + semiColons + "\n" +
      "Device" + ";" + currentSession.deviceName + semiColons + "\n" +
      "Session_ID" + ";" + currentSession._id + semiColons + "\n" +
      "SessionStartTime" + ";" + currentSession.sessionStartTime + semiColons + "\n" +
      "SessionStatus" + ";" + currentSession.status + "\n" +
      "SessionEndTimeApp" + ";" + currentSession.sessionEndTimeApp + semiColons + "\n" +
      "SessionEndTimeDevice" + ";" + currentSession.sessionEndTimeDevice + semiColons + "\n" +
      "Flags" + ";" + currentSession.flags + semiColons + "\n" +
      "OvsRate" + ";" + currentSession.ovsRate + semiColons + "\n" +
      "DefaultDeltaTime" + ";" + sessionParm.defaultDeltaTime + semiColons + "\n" +
      "ksg" + ";" + sessionParm.ksg + semiColons + "\n" +
      "SaturationVoltage" + ";" + sessionParm.saturationVoltage + semiColons + "\n" +
      "IntermissionTime_ms" + ";" + currentSession.intermissionTime_ms + semiColons + "\n" +
      "FirstPointSamplingDelay_ms" + ";" + currentSession.firstPointSamplingDelay_ms + semiColons + "\n" +
      "TargetedUweAtT1e_mV" + ";" + currentSession.targetedUweAtT1e_mV + semiColons + "\n" +
      "MeasurementPeriod_s" + ";" + currentSession.measurementPeriod_s + semiColons + "\n" +
      "NumberOfMeasurements" + ";" + currentSession.numberOfMeasurements + semiColons + "\n" +
      "WECOM_mV" + ";" + currentSession.wecom_mV + semiColons + "\n" +
      "RE_mV" + ";" + currentSession.re_mV + semiColons + "\n" +
      "Download_Time" + ";" + timeNow + semiColons + "\n" +
      "firmwareVersion" + ";" + currentSession.firmwareVersion + semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n" +
      semiColons + "\n";


    const headings = {
      "Empty Column": "",
      "time": "",
      "type": "",
      "code": "",
      "description": "",
    }

    let logsArray = [];
    setApiLoader(true);
    const result = await dispatch(getLogsBySession(_id, modus2));

    console.log("Logs DATA", result);

    setApiLoader(false);
    spin.classList.add('invisible');

    let resultCsv = result && result.logs && result.logs.length > 0 ? result.logs : [];
    // // // // console.log("resultCsv", resultCsv);
    resultCsv.map((log, index) => {
      let obj = {
        "Empty Column": "",
        "time": log.time,
        "type": log.type,
        "code": log.code,
        "description": log.description,


      }

      return logsArray.push(obj);

    });

    // // // // console.log("LOGS ARRAY", logsArray);
    const fileName = moment(currentSession.sessionStartTime).utc().format("YYYYMMDD") + "_" + moment(currentSession.sessionStartTime).utc().format("HHmm") + "_" + currentSession.deviceName + "_" + "events";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += logsArray.map((j) => Object.values(j).join(";")).join("\n");
    csvString += "\n";


    setCsv(`${csvString}`);
    let csvData = csvString;
    // // // // // console.log("csvType", typeof (csvData));
    //
    if (safari) {
      downloadLogs(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
      downloadLogs("\uFEFF" + csvData, fileName, 'text/csv;charset=utf-8;');
      // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
  const downloadLogs = (processedData, filename, type) => {
    let data = processedData;

    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }



  return (
    <>
      {userDetails && userDetails.user && (userDetails.user.role.includes(6) === true || userDetails.user.role.includes(1) === true || userDetails.user.role.includes(0) === true) ?
        <div className="row h-100 mw-100 ml-auto mr-auto" style={{ maxHeight: "90vh", overflow: "auto" }}>

          <div className="col-12">

            <div className="row  justify-content-md-between mw-100 m-auto justify-content-center  ml-md-3 ml-lg-0">
              <div className="col-12">
                <div className="row mw-100 position-fixed stic-top" style={{ width: "83%", background: "white", zIndex: "1", margin: "-1%" }}>
                  <div className="col-12 col-md-10">
                    <h1 className="pt-3 pb-3 font-weight-bold chart-heading">You are viewing the data of "{subId.replace(":", " ")} "</h1>
                  </div>
                  {showDelBtn ?

                    <div className="col-12 col-md-2 d-flex">
                      <button className="btn btn-primary m-auto" onClick={() => setDeleteModal(true)}>Delete Sessions</button>
                    </div>

                    : null}
                </div>
              </div>


              <div className="col-12" style={{ marginTop: "6%" }}>


                <table className="table session-table" id="tab1">
                  <thead style={{ textAlign: 'center' }}>
                    <tr>

                      <th>Device</th>
                      <th>Start Time</th>
                      <th>Status</th>
                      <th>Count</th>
                      <th>Actions</th>


                      <th className="text-left">
                        <input type="checkbox" title={checked ? "Deselect All" : "Select All"} name="checkbox" style={{ veritcalAlign: "middle", width: "20px", height: "20px" }} className="ml-2" onChange={() => { setChecked(!checked); selectAll() }}></input>

                      </th>



                    </tr>
                  </thead>
                  {isPending ? <Loading /> :
                    <tbody>
                      {cliniciansSessionsData !== null && cliniciansSessionsData.sessionData && cliniciansSessionsData.sessionData.length > 0 && cliniciansSessionsData.sessionData.map((session, index) => (
                        <Table row={session}
                          onClick={() => { convert(session, session._id, index) }}
                          onMeasure={() => { convertMeasure(session, session._id, index, cliniciansSessionsData.sessionParams) }}
                          onPoint={() => { convertPoint(session, session._id, index, cliniciansSessionsData.sessionParams) }}

                          onLogs={() => { convertLogs(session, session._id, index, cliniciansSessionsData.sessionParams) }}
                          deleteModal={() => { setDeleteModal(true) }}
                          makeDelArray={() => { makeDelArray(session._id) }}
                          modus={modus2}
                          count={index} key={index} />
                      ))}
                    </tbody>}
                </table>


                <div
                  tabIndex="-1"
                  id="myModal-delete"
                  className={`modal  ${deleteModal ? "d-block fading" : " d-none"
                    }`}
                >
                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box">
                          <i className="fas fa-trash-alt text-danger"></i>
                        </div>
                        <h4 className="modal-heading w-100 text-black">
                          Are you sure?
                        </h4>
                        <button
                          type="button"
                          className="close"
                          onClick={closeDeleteModal}
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>
                          Do you really want to delete this session? This
                          process cannot be undone.
                        </p>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={closeDeleteModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(event) => {
                            forDelete(
                              sessionsToBeDeleted
                            );
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </div> :
        <div className="row mw-100 m-auto">
          <div className="col-12">
            <h1 className="text-center" style={{ height: "85vh" }}>You Are Not Athorized</h1>
          </div>
        </div>}
    </>
  );
}

export default ViewCliniciansData;
