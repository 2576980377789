import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import UserRegisterChart from "../Components/Reports/Charts/AnalyticsCharts/UserRegister";
import UserActivenessChart from "../Components/Reports/Charts/AnalyticsCharts/UsersActiveness";
import DevicesChart from "../Components/Reports/Charts/AnalyticsCharts/DevicesChart";
import DiabetiesChart from "../Components/Reports/Charts/AnalyticsCharts/DiabtiesTypeChart";
import RegionChart from "../Components/Reports/Charts/AnalyticsCharts/RegionChart";
import GeographicChart from "../Components/Reports/Charts/AnalyticsCharts/MapChart";
import AgesDistribution from "../Components/Reports/Charts/AnalyticsCharts/AgesDistribution";
import RegionViews from "../Components/Reports/Analytics/RegionsViews";
import { getAnalytics } from "../redux/actions/admin/analytics";
import { filterFromDate, filterToDate } from "../redux/actions/user";
import AllFilters from '../Components/Common/Filters';
import moment from "moment";



const AnalyticsPage = () => {
    const userData = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("Choose Data Range");
    const [filter, setFilter] = useState("30 days");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [rangFilter, setRangeFilter] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [daysDif, setDaysDif] = useState("");
    const [dropMenu, setDropMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [organizedData, setOrganizedData] = useState([]);


    useEffect(() => {
        fetchAnalytics();
    }, []);
    const fetchAnalytics = async () => {
        let data = { time: filter };
        dispatch(getAnalytics(data));
    }



    const fetchFilterReadings = (e) => {
        // console.log("event", e.target);
        setStartDate("");
        setEndDate("");
        //setShowDate(false);
        setFilter(e.target.name);
        setFilterText(e.target.name);
        // setWeekFilter(true);
        // setTodayFilter(false);
        // setMonthFilter(false);
        setRangeFilter(false);
        setIsLoading(true);
        setDropMenu(false);


        if (filter !== e.target.name) {
            setOrganizedData([]);
        }


        let data = {};

        data.time = e.target.id;



        dispatch(getAnalytics(data));

        if (rangFilter === false) {
            // console.log("CALLED IN FIL");
            let start = findStartDate(e.target.name);
            console.log("start", start);
            setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
            setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
            const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
            const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
            // console.log("fromDat", start);
            dispatch(filterFromDate(fromDat));
            dispatch(filterToDate(toDat));
        } else if (rangFilter === true) {

        }
        setIsLoading(false);

    }

    const fetchRangeReadings = () => {
        setIsLoading(true);
        setFilter("");
        if (startDate !== "" && endDate !== "") {
            let a = moment(startDate);
            let b = moment(endDate);
            let dif = moment.duration(b.diff(a)).asDays();
            let finalDif = dif > 1 ? dif + " Days" : dif + " Day";
            // // // // console.log(typeof(finalDif));

            setDaysDif(finalDif);


            setOrganizedData([]);

            let data = {};



            data.start_date = startDate;
            data.end_date = endDate;



            dispatch(getAnalytics(data));
            // console.log("RESS :", ress);
            // setChartData(ress);

            if (rangFilter === false) {
                let start = findStartDate(filter);

                setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
                setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
                const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
                const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
                dispatch(filterFromDate(fromDat));
                dispatch(filterToDate(toDat));
            } else if (rangFilter === true) {

                setFromDate(moment(startDate).format('D MMM YYYY , h:mm a').toString());
                setToDate(moment(endDate).format('D MMM YYYY , h:mm a').toString());

                const fromDat = moment(startDate).format('D MMM YYYY , h:mm a').toString();
                const toDat = moment(endDate).format('D MMM YYYY , h:mm a').toString();
                dispatch(filterFromDate(fromDat));
                dispatch(filterToDate(toDat));
            }
        }
        setIsLoading(false);
    }

    const findStartDate = (filter) => {
        console.log("FILTERS DAY", filter);

        let currentDate = new Date();

        if (filter === "30days" || filter === "30 days") {
            let start = moment().subtract(30, 'days');
            return new Date(start._d);
        } else if (filter === "7days" || filter === "7 days") {
            let start = moment().subtract(7, 'days');

            return new Date(start._d);


        } else if (filter === "15days" || filter === "15 days") {

            let start = moment().subtract(14, 'days');
            return new Date(start._d);

        } else if (filter === "90days" || filter === "90 days") {

            let start = moment().subtract(90, 'days');
            return new Date(start._d);

        } else if (filter === "today") {

            return new Date();

        } else {

        }

    }
    return (
        <div className="container-fluid h-100 bg-white activity">
            <div className='row m-auto'>
                <div className='col-12'>
                    <div className="">
                        <div className="d-flex justify-content-between mb-2">
                            <div className="chart-heading mt-3 loginNew-HCP-panel">
                                <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                                    Analytics
                                </span>
                            </div>
                        </div>
                        <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
                            setFilter={setFilter}
                            daysDif={daysDif} fromDate={fromDate} toDate={toDate}
                            startDate={startDate} fetchFilterReadings={fetchFilterReadings}
                            fetchRangeReadings={fetchRangeReadings}
                            endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
                            setStartDate={setStartDate} setEndDate={setEndDate}
                            filterText={filterText} loader={setIsLoading} setFilterText={setFilterText}
                            showDays="" showRange={true} />
                    </div>
                </div>
            </div>


            <div className="row h-100 bg-white">
                <div className="col-12">
                    <div className="row m-auto activity-users" >
                        <div className="col-12 col-md-4 bg-light chart m-2">
                            <h1>Users</h1>

                            <UserRegisterChart />
                        </div>
                        <div className="col-12 col-md-3 bg-light chart m-2">
                            <h1>Users Activity</h1>

                            <UserActivenessChart />
                        </div>
                        <div className="col-12 col-md-4 bg-light chart m-2">
                            {/* Third div content */}
                            <RegionViews />
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row m-auto activity-content">
                        <div className="col-12 col-md-9">
                            <div className="row m-auto pb-3">
                                <div className="col-12 col-md-6 bg-light chart ml-4">
                                    <h1>Devices</h1>
                                    <DevicesChart />
                                </div>
                                <div className="col-12 col-md-5 bg-fade bg-light">
                                    <AgesDistribution />
                                </div>

                            </div>
                            <div className="row m-auto">
                                <div className="col-12 col-md-6 bg-light chart ml-4">
                                    <h1>Diabeties</h1>
                                    <DiabetiesChart />

                                </div>
                                <div className="col-12 col-md-5 bg-light">
                                    <h1>Region</h1>
                                    <RegionChart />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 bg-light ">
                            <h1>Geographic</h1>
                            <GeographicChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AnalyticsPage;
