export const FETCH_PATIENT_START = "FETCH_PATIENT_START"
export const FETCH_PATIENT_SUCCESS = "FETCH_PATIENT_SUCCESS"
export const FETCH_PATIENT_FAILED = "FETCH_PATIENT_FAILED"

export const FETCH_ENGINEER_START = "FETCH_ENGINEER_START"
export const FETCH_ENGINEER_FAILED = "FETCH_ENGINEER_FAILED"
export const FETCH_ENGINEER_SUCCESS = "FETCH_ENGINEER_SUCCESS"

export const FETCH_SIMPLE_ENGINEER_START = "FETCH_SIMPLE_ENGINEER_START"
export const FETCH_SIMPLE_ENGINEER_FAILED = "FETCH_SIMPLE_ENGINEER_FAILED"
export const FETCH_SIMPLE_ENGINEER_SUCCESS = "FETCH_SIMPLE_ENGINEER_SUCCESS"



export const FETCH_ADMIN_START = "FETCH_ADMIN_START"
export const FETCH_ADMIN_FAILED = "FETCH_ADMIN_FAILED"
export const FETCH_ADMIN_SUCCESS = "FETCH_ADMIN_SUCCESS"

export const FETCH_CLINICIAN_START = "FETCH_CLINICIAN_START"
export const FETCH_CLINICIAN_FAILED = "FETCH_CLINICIAN_FAILED"
export const FETCH_CLINICIAN_SUCCESS = "FETCH_CLINICIAN_SUCCESS"


export const MAKE_ENGINEER_START = "MAKE_ENGINEER_START"
export const MAKE_ENGINEER_FAILED = "MAKE_ENGINEER_FAILED"
export const MAKE_ENGINEER_SUCCESS = "MAKE_ENGINEER_SUCCESS"

export const MAKE_CLINICIAN_START = "MAKE_CLINICIAN_START"
export const MAKE_CLINICIAN_FAILED = "MAKE_CLINICIAN_FAILED"
export const MAKE_CLINICIAN_SUCCESS = "MAKE_CLINICIAN_SUCCESS"

export const ADD_USER_START = "ADD_USER_START"
export const ADD_USER_FAILED = "ADD_USER_FAILED"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"

export const ADD_CLINICAL_ADMIN_START = "ADD_CLINICAL_ADMIN_START"
export const ADD_CLINICAL_ADMIN_FAILED = "ADD_CLINICAL_ADMIN_FAILED"
export const ADD_CLINICAL_ADMIN_SUCCESS = "ADD_CLINICAL_ADMIN_SUCCESS"

export const ADD_CLINICIAN_START = "ADD_CLINICIAN_START"
export const ADD_CLINICIAN_FAILED = "ADD_CLINICIAN_FAILED"
export const ADD_CLINICIAN_SUCCESS = "ADD_CLINICIAN_SUCCESS"

export const FETCH_CLINICIANS_START = "FETCH_CLINICIANS_START"
export const FETCH_CLINICIANS_FAILED = "FETCH_CLINICIANS_FAILED"
export const FETCH_CLINICIANS_SUCCESS = "FETCH_CLINICIANS_SUCCESS"

export const FETCH_SUBJECTS_START = "FETCH_SUBJECTS_START"
export const FETCH_SUBJECTS_FAILED = "FETCH_SUBJECTS_FAILED"
export const FETCH_SUBJECTS_SUCCESS = "FETCH_SUBJECTS_SUCCESS"

export const FETCH_ALL_SUBJECTS_START = "FETCH_ALL_SUBJECTS_START"
export const FETCH_ALL_SUBJECTS_FAILED = "FETCH_ALL_SUBJECTS_FAILED"
export const FETCH_ALL_SUBJECTS_SUCCESS = "FETCH_ALL_SUBJECTS_SUCCESS"

export const FETCH_ENGINEER_DATA_START = "FETCH_ENGINEER_DATA_START"
export const FETCH_ENGINEER_DATA_FAILED = "FETCH_ENGINEER_DATA_FAILED"
export const FETCH_ENGINEER_DATA_SUCCESS = "FETCH_ENGINEER_DATA_SUCCESS"

export const FETCH_SENSOR_LOGS_DATA_START = "FETCH_SENSOR_LOGS_DATA_START"
export const FETCH_SENSOR_LOGS_DATA_FAILED = "FETCH_SENSOR_LOGS_DATA_FAILED"
export const FETCH_SENSOR_LOGS_DATA_SUCCESS = "FETCH_SENSOR_LOGS_DATA_SUCCESS"

export const FETCH_ENGINEER_SESSIONS_DATA_START = "FETCH_ENGINEER_SESSIONS_DATA_START"
export const FETCH_ENGINEER_SESSIONS_DATA_FAILED = "FETCH_ENGINEER_SESSIONS_DATA_FAILED"
export const FETCH_ENGINEER_SESSIONS_DATA_SUCCESS = "FETCH_ENGINEER_SESSIONS_DATA_SUCCESS"

export const FETCH_CLINICIANS_SESSIONS_DATA_START = "FETCH_CLINICIANS_SESSIONS_DATA_START"
export const FETCH_CLINICIANS_SESSIONS_DATA_FAILED = "FETCH_CLINICIANS_SESSIONS_DATA_FAILED"
export const FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS = "FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS"

export const FETCH_SESSIONS_PARAMS_START = "FETCH_SESSIONS_PARAMS_START"
export const FETCH_SESSIONS_PARAMS_FAILED = "FETCH_SESSIONS_PARAMS_FAILED"
export const FETCH_SESSIONS_PARAMS_SUCCESS = "FETCH_SESSIONS_PARAMS_SUCCESS"

export const FETCH_ENGINEER_MEASUREMENT_DATA_START = "FETCH_ENGINEER_MEASUREMENT_DATA_START"
export const FETCH_ENGINEER_MEASUREMENT_DATA_FAILED = "FETCH_ENGINEER_MEASUREMENT_DATA_FAILED"
export const FETCH_ENGINEER_MEASUREMENT_DATA_SUCCESS = "FETCH_ENGINEER_MEASUREMENT_DATA_SUCCESS"

export const FETCH_SENSOR_LOGS_BY_SESSION_START = "FETCH_SENSOR_LOGS_BY_SESSION_START"
export const FETCH_SENSOR_LOGS_BY_SESSION_FAILED = "FETCH_SENSOR_LOGS_BY_SESSION_FAILED"
export const FETCH_SENSOR_LOGS_BY_SESSION_SUCCESS = "FETCH_SENSOR_LOGS_BY_SESSION_SUCCESS"


export const SAVE_APP_SUCCESS = "SAVE_APP_SUCCESS"
export const SAVE_APP_MODUS_SUCCESS = "SAVE_APP_MODUS_SUCCESS"

export const FETCH_ANALYTICS_START = "FETCH_ANALYTICS_START"
export const FETCH_ANALYTICS_FAILED = "FETCH_ANALYTICS_FAILED"
export const FETCH_ANALYTICS_SUCCESS = "FETCH_ANALYTICS_SUCCESS"


export const FETCH_TOTAL_USERS_START = "FETCH_TOTAL_USERS_START"
export const FETCH_TOTAL_USERS_FAILED = "FETCH_TOTAL_USERS_FAILED"
export const FETCH_TOTAL_USERS_SUCCESS = "FETCH_TOTAL_USERS_SUCCESS"


export const FETCH_ACTIVE_USERS_START = "FETCH_ACTIVE_USERS_START"
export const FETCH_ACTIVE_USERS_FAILED = "FETCH_ACTIVE_USERS_FAILED"
export const FETCH_ACTIVE_USERS_SUCCESS = "FETCH_ACTIVE_USERS_SUCCESS"


export const FETCH_COUNTRIES_START = "FETCH_COUNTRIES_START"
export const FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED"
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS"

export const FETCH_COUNTRIES_USERS_SUCCESS = "FETCH_COUNTRIES_USERS_SUCCESS"
export const FETCH_COUNTRIES_CITIES_SUCCESS = "FETCH_COUNTRIES_CITIES_SUCCESS"
export const FETCH_COUNTRIES_PERCENTAGE_SUCCESS = "FETCH_COUNTRIES_PERCENTAGE_SUCCESS"

export const ADD_NOTIFICATIONS_START = "ADD_NOTIFICATIONS_START"
export const ADD_NOTIFICATIONS_FAILED = "ADD_NOTIFICATIONS_FAILED"
export const ADD_NOTIFICATIONS_SUCCESS = "ADD_NOTIFICATIONS_SUCCESS"

export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START"
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED"
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS"