import * as actions from "../actionTypes";
import {
  API_BASE_URL,
  loginURL,
  forgotPasswordURL,
  createUserURL,
  resetPasswordURL,
  resendVerificationEmailURL,
  accountActicationUrl,
  newEmailConfirm,
  newEmailReq,
  newEmailConfirmReq,
  deleteAccountURL
} from "../../utils/urls";
import http from "../../utils/http-service";
import { fetchUser } from "./user";
import {encrypt,decrypt} from "../../utils/encryption";
import {updateUserRole} from "./user";
import ShowToast from "../../utils/show-toast";



const loginStart = () => {
  return { type: actions.LOGIN_START };
};

const loginFailed = () => {
  return { type: actions.LOGIN_FAILED };
};

export const loginSuccess = (payload) => {
  return { type: actions.LOGIN_SUCCESS, payload };
};

export const login = (data) => async (dispatch) => {

  try {
    dispatch(loginStart());
    const { email, password } = data;

    let loginData = {
      email,
      password
    };
    let decData;
    loginData = JSON.stringify(loginData);
    loginData = await encrypt(loginData);
    const result = await http.post(`${API_BASE_URL}${loginURL}`, {data: loginData});
    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      // console.log("LOGIN DATA", data);

      data = await decrypt(data);
      data = JSON.parse(data);
      localStorage.setItem("EostreAuthToken", data.token);
      dispatch(loginSuccess(data));
      if(localStorage.getItem("Apple")){
        let user = localStorage.getItem("Apple");
        user = decrypt(user);
        // user = JSON.parse(user);
        dispatch(updateUserRole(user));
      } else {
        dispatch(fetchUser());

      }
    }

    return result;
  } catch (error) {

    console.log("IN LOGIN", error);
    ShowToast(error.response.data.message, "error")
    dispatch(loginFailed());
    // throw error;
  }
};

export const logout = () => {
  // console.log("window.location.pathname in logout", window.location.pathname);
  if( window.location.pathname !== "/auth/login" && window.location.pathname !== "/dashboard" && window.location.pathname !== "/export-data" && window.location.pathname !== "/auth/deleteUser"){

    localStorage.setItem("pathname", window.location.pathname);

  } else if(window.location.pathname === "/auth/deleteUser"){
    let comPath = window.location.pathname;
    let access_token = new URLSearchParams(window.location.search).get('token');
    comPath  = comPath+"?token="+access_token;
    // console.log("comPath", comPath);
    localStorage.setItem("pathname", comPath);
  }
   if(window.location.pathname === "/auth/updateEmail"){
    // console.log("updateEmail");
    if((!!localStorage.getItem("token")) === false){
let url = window.location;
    let token = new URLSearchParams(url.search).get('token');
    if(token !== "" && token !== null){
      // console.log("Yes Token generated", token);
      localStorage.setItem("pathname", window.location.pathname);

    localStorage.setItem("token", token);
  }
  }
  }

  localStorage.removeItem("EostreAuthToken");
  localStorage.removeItem("token");
  localStorage.removeItem("pathname");

  localStorage.removeItem("Apple");

  return {
    type: actions.USER_LOGGED_OUT,
  };
};

const registerStart = () => {
  return { type: actions.REGISTER_START };
};

const registerFailed = () => {
  return { type: actions.REGISTER_FAILED };
};

const registerSuccess = (payload) => {
  return { type: actions.REGISTER_SUCCESS, payload };
};

export const register = (data) => async (dispatch) => {
  try {
    dispatch(registerStart());
    // let registerData={...data}

    // registerData=JSON.stringify(registerData)
    // registerData = encrypt(registerData);
    const result = await http.post(`${API_BASE_URL}${createUserURL}`, data);

    if (result.status === 200) {
      const {
        data: { data },
      } = result;


      delete data.token;
      dispatch(registerSuccess(data));
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(registerFailed());
    throw error;
  }
};

const forgotPasswordStart = () => {
  return { type: actions.FORGOT_PASSWORD_START };
};

const forgotPasswordFailed = () => {
  return { type: actions.FORGOT_PASSWORD_FAILED };
};

const forgotPasswordSuccess = (payload) => {
  return { type: actions.FORGOT_PASSWORD_SUCCESS, payload };
};

export const forgotPassword = (data) => async (dispatch) => {
  // console.log("FORgot", data);
  dispatch(forgotPasswordStart());

  try {
    let forgetData=data;
    forgetData=JSON.stringify(forgetData);
    forgetData=await encrypt(forgetData);
    const result = await http.post(`${API_BASE_URL}${forgotPasswordURL}`, {data:forgetData});

    dispatch(forgotPasswordSuccess(result));

    return result;
  } catch (error) {
    ShowToast(error.response.data.message,"error");

    dispatch(forgotPasswordFailed());
    // throw error;
  }
};

const resetPasswordStart = () => {
  return { type: actions.RESET_PASSWORD_START };
};

const resetPasswordFailed = () => {
  return { type: actions.RESET_PASSWORD_FAILED };
};

const resetPasswordSuccess = (payload) => {
  return { type: actions.RESET_PASSWORD_SUCCESS, payload };
};

export const resetPassword = (data) => async (dispatch) => {
  dispatch(resetPasswordStart());

  try {
    let resetData=data
    resetData=JSON.stringify(resetData)
    resetData=await encrypt(resetData)

    const result = await http.post(`${API_BASE_URL}${resetPasswordURL}`, {data:resetData});
    dispatch(resetPasswordSuccess(result));


    return result;
  } catch (error) {
    dispatch(resetPasswordFailed());
    throw error;
  }
};

const resendVerificationEmailStart = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_START };
};

const resendVerificationEmailFailed = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_FAILED };
};

const resendVerificationEmailSuccess = (payload) => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_SUCCESS, payload };
};

export const resendVerificationEmail = (data) => async (dispatch) => {
  try {
    dispatch(resendVerificationEmailStart());
    let resendEmailData=data
    resendEmailData=JSON.stringify(resendEmailData)
    resendEmailData= await encrypt(resendEmailData)
    const result = await http.post(
      `${API_BASE_URL}${resendVerificationEmailURL}`,
      {data:resendEmailData}
    );
    ShowToast(result.data.message,"success");

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data=await decrypt(data);
      data=JSON.parse(data);
      dispatch(resendVerificationEmailSuccess(data));
      
      console.log("RESULT :", result);
    }

    return result;
  } catch (error) {
    dispatch(resendVerificationEmailFailed());
    ShowToast(error.response.data.message,"error");

    // throw error;
  }
};
export const accountActivation = (token) => async (dispatch) => {
  let encToken = await encrypt(token);
  let result;
  try {

    // let resetData=data
    // resetData=JSON.stringify(resetData)
    // resetData=encrypt(resetData)

    result = await http.get(`${API_BASE_URL}${accountActicationUrl}?token=${encToken}`);


    ShowToast(result.data.message,"success");
    return result;

  } catch (error) {
    console.log("ERROR FROM ACTION: ", error);
    dispatch(resetPasswordFailed());
    if( error.response.status===409){
      // ShowToast(error.response.data.message,"success");
    }
    else{
      // ShowToast(error.response.data.message,"error");
    }
    ShowToast(error.response.data.message,"error");
    // throw error;
     return error.response;
  }
};

export const deleteAccountReq = () => async (dispatch) => {

  try {

    const  result = await http.delete(`${API_BASE_URL}${deleteAccountURL}`);

    // // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};

export const deleteAccountConfirm = (token) => async (dispatch) => {

  let encTok = await encrypt(token);

  try {

    const  result = await http.delete(`${API_BASE_URL}/user/verify?token=${encTok}`);

    // // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};

// update emial Request
export const resetEmail=(email)=> async(dispatch)=>{
try {
  const res = await http.get(`${API_BASE_URL}${newEmailReq}`);
// ShowToast(res.data.message,"success");
return res;
} catch (error) {
  if( error.response.status===409){
    // ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
}

// email update confirm
export const resetEmailConfirm=(email,token)=> async(dispatch)=>{
// // // console.log("TOKEN :", token);
try {
  const res = await http.post(`${API_BASE_URL}${newEmailConfirmReq}`,{"email":email,"token":token});
ShowToast(res.data.message,"success");
return res;
} catch (error) {
  if( error.response.status===409){
    ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
};
// email update req confirm
export const resetEmailConfirmReq=(token)=> async(dispatch)=>{
// // console.log("TOKEN :", token);
try {
  const res = await http.post(`${API_BASE_URL}${newEmailConfirm}`,{"token":token});
// ShowToast(res.data.message,"success");
dispatch(logout());
return res;
} catch (error) {
  if( error.response.status===409){
    ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
};
