// import { toast as message } from "react-toastify";

// const ShowToast = (msg, type = "info") => {

//   switch (type) {
//     case "info":
//       message.info(msg);
//       break;
//     case "success":
//       message.success(msg);
//       break;
//     case "warning":
//       message.warn(msg);
//       break;
//     case "error":
//       message.error(msg);
//       break;
//     default:
//       message.info(msg);
//       break;
//   }
// };

// export default ShowToast;


import { toast as message } from "react-toastify";

const ShowToast = (msg, type = "info", options = {}) => {
  const defaultOptions = {
    autoClose: 5000, // Default auto-close after 5 seconds
  };

  const toastOptions = { ...defaultOptions, ...options };

  switch (type) {
    case "info":
      message.info(msg, toastOptions);
      break;
    case "success":
      message.success(msg, toastOptions);
      break;
    case "warning":
      message.warn(msg, toastOptions);
      break;
    case "error":
      message.error(msg, toastOptions);
      break;
    default:
      message.info(msg, toastOptions);
      break;
  }
};

export default ShowToast;