import {
    API_BASE_URL,
} from "../../../utils/urls";
import {
    FETCH_NOTIFICATIONS_START,
    FETCH_NOTIFICATIONS_FAILED,
    FETCH_NOTIFICATIONS_SUCCESS,
    ADD_NOTIFICATIONS_START,
    ADD_NOTIFICATIONS_FAILED,
    ADD_NOTIFICATIONS_SUCCESS,

} from "../../actionTypes/admin";
import http from "../../../utils/http-service";

import ShowToast from "../../../utils/show-toast";
import moment from "moment";
import { encrypt, decrypt } from "../../../utils/encryption";


const getNotificationsStart = () => {
    return { type: FETCH_NOTIFICATIONS_START };
}
const getNotificationsFailed = () => {
    return { type: FETCH_NOTIFICATIONS_FAILED };
}
export const getNotifications = (data) => async (dispatch) => {
    console.log("data", data);
    let noWhitespaceStr = "";
    let dayDif;
    dispatch(getNotificationsStart());
    // // role = JSON.stringify(role);
    // let user = await encrypt(patient);
    // let encApp = await encrypt(app);
    try {
        if (data && data.time) {
            noWhitespaceStr = data.time.replace(/\s+/g, '');
            let requestedData = {
                "dateRanges": [{ "startDate": `${noWhitespaceStr}Ago`, "endDate": "today" }],
                "dimensions": [{ "name": "yearMonth" }],
                "metrics": [{ "name": "activeUsers" }, { "name": "newUsers" }, { "name": "totalUsers" }]
            }
            console.log("requestedData", requestedData);
            requestedData = JSON.stringify(requestedData);
            requestedData = await encrypt(requestedData);
            const res = await http.post(`${API_BASE_URL}/admin/notifications`, { data: requestedData });
            console.log("res", res.data.data);

            let responseData = await decrypt(res.data.data);

            responseData = JSON.parse(responseData);
            console.log("responseData", responseData);
// other apis call

            // dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload:  responseData});

        } else if (data && data.start_date) {
            console.log("IM HERE");
            let bg_start = new Date(data.start_date);
            bg_start = bg_start.toISOString();
            console.log("bg_start", bg_start);

            let bg_end = new Date(data.end_date);
            bg_end = bg_end.toISOString();

            // Parse date strings using Moment.js
            let date1 = moment.utc(bg_start);
            let date2 = moment.utc(bg_end);
            let today = new Date();
            today = moment.utc(today);
            // Compare day, month, and year
            if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
                console.log("HERE");
                if (date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')) {
                    bg_end = new Date();
                    bg_end = bg_end.toISOString();
                } else {
                    bg_end = convertLastDates(bg_end);
                }

            }
            // else {
            //   console.log("HERE ELSE", bg_end);
            //   bg_end= bg_end.toISOString();
            //
            // }

            // bg_start = convertDates(data.start_date);
            // bg_start = await encrypt(bg_start);
            date1 = moment(date1).format("YYYY-MM-DD");
            date2 = moment(date2).format("YYYY-MM-DD");

            // bg_end = await encrypt(bg_end);
            dayDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
            // encType = JSON.stringify(encType);
            // encType = await encrypt(encType);
           

            const res = await http.post(`${API_BASE_URL}/admin/notifications`, {data: {start_date: date1, end_date: date2}});
            


            console.log("res   ", res);

            let responseData = res.data.data;
            responseData = await decrypt(responseData);
            responseData = JSON.parse(responseData);
          
            dispatch({ type: FETCH_NOTIFICATIONS_SUCCESS, payload: responseData });

           
        }


        //   return res;
    } catch (error) {
        // dispatch(getAnalyticsFailed())
        ShowToast(error.message, "error");

    }
}


const convertLastDates = (date) => {
    let conDate = new Date(date);
    conDate = conDate.setHours(0);
    conDate = new Date(conDate);
    console.log("hours", conDate.getHours());

    conDate = moment(conDate).add({ hours: 23, minutes: 59 });
    // conDate = conDate.setHours(23);
    // console.log("hours", conDate.getHours());

    // conDate = conDate.setHours(conDate.getHours() + 23);
    // conDate = conDate.setMinutes(conDate.getMinutes() + 59);
    console.log("conDate after adding hours and mintues", conDate);
    conDate = conDate.toISOString();
    console.log("CONDATE", conDate);
    return conDate;

}