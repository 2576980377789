import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Spin } from "antd";
// import { getUser, suspendUser, removeUser, addDoctor, removeDoctor } from "../../../redux/actions/admin/admin";
import UploadImage from "../../../Assets/upload_icon.svg";
import AllFilters from "../../Common/Filters";
import { getNotifications } from "../../../redux/actions/admin/notifications";
import { filterFromDate, filterToDate } from "../../../redux/actions/user";


import moment from "moment";
import { Filter } from "@amcharts/amcharts4/core";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const Notifications = () => {
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [customData, setCustomData] = useState(false);
  const [customDataRows, setCustomDataRows] = useState([]);


    const [filterText, setFilterText] = useState("Choose Data Range");
    const [filter, setFilter] = useState("30 days");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [rangFilter, setRangeFilter] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [daysDif, setDaysDif] = useState("");
    const [dropMenu, setDropMenu] = useState(false);
    const [isLoading, setIsLoading] = useState(true);










  // CSV
  // const [json, setJson] = useState("");


  useEffect(() => {
    //       let newData = customDataRows;

    //       let obj = {
    //         key: "",
    //         value: "",
    //       };
    //       newData.push(obj);
    //       setCustomDataRows(newData);


  }, [customDataRows])




  // const usersData = useSelector((state) => {
  //   // setJson(state.admin.users);
  //
  //   return state.admin.users;
  // });
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const addValue = (event) => {

    let newData = customDataRows;

    let obj = {
      key: "",
      value: "",
    };
    newData.push(obj);
    setCustomDataRows(newData);
    setCustomData(!customData);
  };
  const removeIndex = (index) => {
    let newData = customDataRows;

    newData.splice(index, 1);
    setCustomDataRows(newData);
    setCustomData(!customData);
    // this.setState({ chronic: newData });
  };
  useEffect(() => {

  }, [customData])


  useEffect(() => {
    fetchAnalytics();
}, []);
const fetchAnalytics = async () => {
    let data = {time: filter};
     dispatch(getNotifications(data));
}



const fetchFilterReadings = (e) => {
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if (filter !== e.target.name) {
        // setOrganizedData([]);
    }


    let data = {};

    data.time = e.target.id;



    // dispatch(getAnalytics(data));

    if (rangFilter === false) {
        // console.log("CALLED IN FIL");
        let start = findStartDate(e.target.name);
        console.log("start", start);
        setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
        setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
        const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
        const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
        // console.log("fromDat", start);
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
    } else if (rangFilter === true) {

    }
    setIsLoading(false);

}

const fetchRangeReadings =  () => {
    setIsLoading(true);
    setFilter("");
    if (startDate !== "" && endDate !== "") {
        let a = moment(startDate);
        let b = moment(endDate);
        let dif = moment.duration(b.diff(a)).asDays();
        let finalDif = dif > 1 ? dif + " Days" : dif + " Day";
        // // // // console.log(typeof(finalDif));

        setDaysDif(finalDif);


        // setOrganizedData([]);

        let data = {};



        data.start_date = startDate;
        data.end_date = endDate;



          // dispatch(getAnalytics(data));
        // console.log("RESS :", ress);
        // setChartData(ress);

        if (rangFilter === false) {
            let start = findStartDate(filter);

            setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
            setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
            const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
            const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
            dispatch(filterFromDate(fromDat));
            dispatch(filterToDate(toDat));
        } else if (rangFilter === true) {

            setFromDate(moment(startDate).format('D MMM YYYY , h:mm a').toString());
            setToDate(moment(endDate).format('D MMM YYYY , h:mm a').toString());

            const fromDat = moment(startDate).format('D MMM YYYY , h:mm a').toString();
            const toDat = moment(endDate).format('D MMM YYYY , h:mm a').toString();
            dispatch(filterFromDate(fromDat));
            dispatch(filterToDate(toDat));
        }
    }
    setIsLoading(false);
}

const findStartDate = (filter) => {
    console.log("FILTERS DAY", filter);

    let currentDate = new Date();

    if (filter === "30days" || filter === "30 days") {
        let start = moment().subtract(30, 'days');
        return new Date(start._d);
    } else if (filter === "7days" || filter === "7 days") {
        let start = moment().subtract(7, 'days');

        return new Date(start._d);


    } else if (filter === "15days" || filter === "15 days") {

        let start = moment().subtract(14, 'days');
        return new Date(start._d);

    } else if (filter === "90days" || filter === "90 days") {

        let start = moment().subtract(90, 'days');
        return new Date(start._d);

    } else if (filter === "today") {

        return new Date();

    } else {

    }

}

  return (
    <div className="container-fluid patient-list">
      {/*<div className="container bg-white rounded my-5 p-3">*/}
      {/* <form onSubmit={handleSubmit}> */}
      <div className="row p-2 px-3">
        <div className="col-12">
          {/*<aside className="col-12 mx-lg-1 mx-0 py-lg-3 py-2 mb-lg-0 mb-2 fixed-top h-100" style={{ background:"rgb(0, 54, 80)",borderRadius:"1em" }}>

                </aside>*/}
          <h1 className="pt-3 pb-3 font-weight-bold chart-heading"><span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">Send Notifications</span></h1>
        </div>
        <div className="col-12">
          <div className="row mw-100 m-auto">
            <form className="needs-validation" noValidate>
              <div className="row mw-100 m-auto">

                <div className="col-12 col-md-4">

                  <label className="text-black registration-input-labels" htmlFor="audience"> Select Audience </label>
                  <select
                    id="audience"
                    placeholder="Select"
                    className="registration-form-control form-control"
                    required
                  >
                    <option value="">Select</option>
                    <option value="groupA">Users Group A</option>
                    <option value="groupB">Users Group B</option>
                    <option value="groupC">Users Group C</option>
                  </select>



                </div>
                <div className="col-12 mt-3">


                  <label className="registration-input-labels" htmlFor="title">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    required
                  />


                </div>
                <div className="col-12 mt-3">
                  <div className="row justify-content-center align-items-center mw-100 m-auto mb-3 text-white">
                    <div className="col-12 text-section">
                      <div className="d-grid ml-auto w-100">
                        <label className="registration-input-labels text-black" htmlFor="comments">Text</label>
                        <textarea id="comments" name="" rows="8" cols="50"
                          className="text-black form-control comment-block w-100"
                          required>
                        </textarea>

                      </div>

                    </div>
                    <div className="col-12 mt-4 section-file">
                      <div className="row mw-100 m-auto">
                        <div className="col-12 registration-input-labels upload-image">
                          {/* <p className="text-black">Upload Image</p> */}
                        </div>
                        <div className="col-12">
                          <div class="upload-section text-black">
                            <h2 className="text-left">Upload Image</h2>
                            <div class="upload-box">
                              <img src={UploadImage} alt="Upload Icon" />
                              <p>Drop Your Image Here, Or&nbsp;
                                <a href="#" onClick={handleClick} id="upload_link">
                                  Browse
                                </a></p>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: 'none' }}
                                id="upload"
                              />
                              <p>Support JPG, PNG, PDF (Size Must Be Less Than 2000 MB)</p>
                            </div>
                          </div>

                          {/* <div class="custom-add-section">
                            <h2>Add Custom Add</h2>
                            <div class="key-value-pair">
                              <input type="text" placeholder="Key" />
                              <input type="text" placeholder="Value" />
                              <span class="remove-btn">&times;</span>
                            </div>
                            <div class="key-value-pair">
                              <input type="text" placeholder="Key" />
                              <input type="text" placeholder="Value" />
                              <span class="remove-btn">&times;</span>
                            </div>
                            <span class="add-btn">+ Add</span>
                            <button class="send-btn">Send</button>
                          </div> */}

                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-5 custom-data text-black">
                  <div className="row mw-100 m-auto">
                    <div className="col-12"><h2 >Add Custom Data</h2></div>
                    <div className="col-12 text-center">
                      <button className="btn btn-primary" disabled={customDataRows.length > 0} onClick={(e) => addValue(e)}> + Add</button>
                    </div>
                    <div className="col-12">
                      {customDataRows && customDataRows.length > 0 && customDataRows.map((row, index) =>

                        <div className="row mt-5 mx-100 ml-auto mr-auto">
                          <div className="col-12 col-md">
                            <label className="registration-input-labels text-black" htmlFor="custom-key">Key</label>
                            <input
                              type="text"
                              className="registration-datePicker form-control"
                              id="custom-key"
                            />
                          </div>
                          <div className="col-12 col-md">
                            <label className="registration-input-labels text-black" htmlFor="custom-value">Value</label>
                            <input
                              type="text"
                              className="registration-datePicker form-control"
                              id="custome-value"
                            />
                          </div>
                          <div className="col-12 col-md-1 actions-tab">
                            <button type="button" onClick={(index) => removeIndex(index)} className=" mt-5">
                              <span className="">
                                <i className="fas fa-trash-alt text-danger"></i>
                              </span>
                            </button>
                            <button type="button" onClick={(e) => addValue(e)} className=" ml-4">
                              <span className="">
                                <i className="fas fa-plus"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      )
                      }
                    </div>

                    <div className="col-12 text-center mt-3">
                      <button className="btn btn-primary" type="submit">Send</button>
                    </div>

                  </div>
                </div>
                  </div>
                </div>
                
              </div>
            </form>

          </div>
        </div>
        <div className="col-12 col-md-12 mt-5">
        <div className='row m-auto'>
                <div className='col-12'>
                <div className="">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="chart-heading mt-3 loginNew-HCP-panel">
                            <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                                Notifications List
                            </span>
                        </div>
                    </div>
                    <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
                        setFilter={setFilter}
                        daysDif={daysDif} fromDate={fromDate} toDate={toDate}
                        startDate={startDate} fetchFilterReadings={fetchFilterReadings}
                        fetchRangeReadings={fetchRangeReadings} startDate={startDate}
                        endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
                        setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} loader={setIsLoading} setFilterText={setFilterText} showDays="" showRange={true} />
                </div>
                </div>
                </div>
          <div className="row mw-100 m-auto">
            <div className="col-md-12">
              <div className="panel panel-success">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                      <th>Notifications</th><th></th><th></th><th></th>
                    </tr>
                    <tr>
                      <th>Time</th><th>Text</th><th>Group</th><th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>08:35 am</td>
                      <td>test8</td>
                      <td> </td>
                      <td><button type="button" className="" data-toggle="modal" data-target="#commonModal"><i className="fas fa-edit text-primary"></i></button><button type="button" className=" ml-4"><span className=""><i className="fas fa-play text-success"></i></span></button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>

        </div>
      </div>
      {/*</div>*/}
    </div>
  );
};

export default Notifications;
