import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchSettings, updateSetting } from "../../redux/actions/settings";
import { deleteAccountReq } from "../../redux/actions/auth";



const SettingComp = () => {
  // const [settings, setSettings]= useState();
  const [language, setLanguage] = useState();
  const [glucoUnit, setGlucoUnit] = useState();
  const [day, setDay] = useState({});
  const [dayStart, setDayStart] = useState();
  const [dayEnd, setDayEnd] = useState();
  const [dayLow, setDayLow] = useState();
  const [dayHigh, setDayHigh] = useState();
  const [night, setNight] = useState({});
  const [nightStart, setNightStart] = useState();
  const [nightEnd, setNightEnd] = useState();
  const [nightLow, setNightLow] = useState();
  const [nightHigh, setNightHigh] = useState();
  const [veryHigh, setVeryHigh] = useState();
  const [veryLow, setVeryLow] = useState();
  const [urgentHigh, setUrgentHigh] = useState();
  const [urgentLow, setUrgentLow] = useState();
  const [notifications, setNotifications] = useState();



  const [lowAlert, setLowAlert] = useState();
  const [highAlert, setHighAlert] = useState();

  const [lowAlarm, setLowAlarm] = useState();
  const [highAlarm, setHighAlarm] = useState();

  const [agpHigh, setAgpHigh] = useState();

  const [agpLow, setAgpLow] = useState();
  const { settings } = useSelector((state) => { return state.settings });
  // console.log("SETTINGS :", settings);

  let _id = settings && settings._id ? settings._id : "";
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // // // // console.log("DATA", settings);
  // },[settings.low]);
  useEffect(() => {
    fetchReadings();
  }, []);

  // useEffect(() => {
  //   // console.log(lowAlert);
  //   // console.log(highAlert);
  //
  // },[lowAlert, highAlert]);
  const fetchReadings = async () => {
    dispatch(fetchSettings());

  };
  const updateSettings = async () => {
    dispatch(updateSetting({
      language: language === undefined ? settings.language : language,
      unit: 0,

      notifications: notifications !== undefined ? notifications : settings.notifications,
      highAlert: highAlert !== undefined ? highAlert : settings.highAlert,

      lowAlert: lowAlert !== undefined ? lowAlert : settings.lowAlert,
      highAlarm: highAlarm !== undefined ? highAlarm : settings.highAlarm,
      lowAlarm: lowAlarm !== undefined ? lowAlarm : settings.lowAlarm,
      urgentLow: urgentLow === undefined ? settings.urgentLow : urgentLow,
      urgentHigh: urgentHigh === undefined ? settings.urgentHigh : urgentHigh,
      low: veryLow === undefined ? settings.low : veryLow,
      high: veryHigh === undefined ? settings.high : veryHigh
    }));
    dispatch(fetchSettings());



  };

  const setAlerts = (e, flag) => {
    // console.log(e.target.name, flag);
    if (e.target.name === "low") {
      setLowAlert(!flag);
    } else if (e.target.name === "high") {
      setHighAlert(!flag);
    } else {
      setNotifications(!flag);
    }
  }

  const startDeleteAccount = async () => {
    try {
      dispatch(deleteAccountReq());
    } catch (e) {

    }
  }
  // // // // console.log("settings", settings);
  return (
    <div className="row mw-100 m-auto settings">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <form className="row mw-100 m-auto">
              <div className="col-12">
                <h1>
                  Language and Unit Settings
                </h1>
              </div>
              <div className="col-12 col-md-6 mt-4 mb-4">
                <div className="m-auto bg-white rounded shadow p-5">
                  <label htmlFor="language" style={{ marginRight: "2%" }}>Language</label>
                  <select className="language-select" id="language"
                    onChange={(event) => {
                      setLanguage(event.target.value);
                    }}
                    defaultValue={settings && settings.language ? settings.language : ""} disabled>
                    <option value="">Select</option>
                    {settings && settings.language ?
                      <option value={settings.language} selected>{settings.language}</option>
                      : null}
                    <option value="Dansk">Dansk</option>
                    <option value="Deutsch">Deutsch</option>
                    <option value="Eesti keel">Eesti keel</option>
                    <option value="English">English</option>
                    <option value="Español">Español</option>
                    <option value="Français">Français</option>
                    <option value="Français (CA)">Français (CA)</option>
                    <option value="Italiano">Italiano</option>
                    <option value="Latviski">Latviski</option>
                    <option value="Lietuvių">Lietuvių</option>
                    <option value="Magyar">Magyar</option>
                    <option value="Nederlands">Nederlands</option>
                    <option value="Norsk (bokmål)">Norsk (bokmål)</option>
                    <option value="Polski">Polski</option>
                    <option value="Português">Português</option>
                    <option value="Slovenčina">Slovenčina</option>
                    <option value="Slovenščina">Slovenščina</option>
                    <option value="Suomi">Suomi</option>
                    <option value="Svenska">Svenska</option>
                    <option value="Türkçe">Türkçe</option>
                    <option value="Čeština">Čeština</option>
                    <option value="български">български</option>
                    <option value="he-IL"> עברית </option>
                    <option value="ar-SA">العربية</option>
                    <option value="ko-KR">한국어</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4 mb-4">

                <div className="m-auto bg-white rounded shadow p-5">
                  <label htmlFor="gluco-unit" style={{ marginRight: "2%" }}>Glucose Unit</label>
                  <select className="glucose-units" id="gluco-unit" disabled="disabled"
                    onChange={(event) => {

                      setGlucoUnit(event.target.value);
                    }}>
                    <option value="mmol/L">mmol/L</option>
                    <option value="mgdl/dL">mmol/L</option>
                  </select>
                </div>

              </div>
              <div className="col-12 mb-5">
                <h1>
                  Target Range (mmol/L)
                </h1>
              </div>
              <div className="col-12">
                <div className="form-group row mw-100 m-auto">
                  <div className="col-12">
                    <div className="row mw-100 m-auto">






                      <div className="col-12 col-md-6 mt-3 ">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">
                          <h3>
                            Low Alert
                          </h3>
                          <div className="m-auto bg-white p-5">
                            {settings && settings.lowAlert === true ?
                              <label className="switch">
                                <input type="checkbox" name="low" defaultChecked={settings.lowAlert} onClick={(e) => { setAlerts(e, settings.lowAlert) }}  disabled/>
                                <span className="slider round"></span>
                              </label>
                              :
                              <label className="switch">
                                <input type="checkbox" name="low" onChange={(e) => { setAlerts(e, settings.lowAlert) }}  disabled/>
                                <span className="slider round"></span>
                              </label>
                            }
                            

                          </div>

                        </div>

                      </div>
                      <div className="col-12 col-md-6 mt-3">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">
                          <h3>
                            High Alert
                          </h3>
                          <div className="m-auto bg-white p-5">
                            {settings && settings.highAlert === true ?
                              <label className="switch">
                                <input type="checkbox" name="high" defaultChecked={settings.highAlert} onClick={(e) => { setAlerts(e, settings.highAlert) }} disabled/>
                                <span className="slider round"></span>
                              </label>
                              :
                              <label className="switch">
                                <input type="checkbox" name="high" onChange={(e) => { setAlerts(e, settings.highAlert) }} disabled/>
                                <span className="slider round"></span>
                              </label>
                            }
                          </div>

                        </div>
                      </div>


                      <div className="col-12 col-md-6 mt-3 ">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">
                          <h3>
                            Low Alarm
                          </h3>
                          <div className="m-auto bg-white p-5">
                            <input type="text" defaultValue={settings.lowAlarm} onChange={(e) => { setLowAlarm(e.target.value) }} disabled/>
                          </div>

                        </div>

                      </div>
                      <div className="col-12 col-md-6 mt-3">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">
                          <h3>
                            High Alarm
                          </h3>
                          <div className="m-auto bg-white p-5">
                            <input type="text" defaultValue={settings.highAlarm} onChange={(e) => { setHighAlarm(e.target.value) }}  disabled/>

                          </div>

                        </div>
                      </div>








                      <div className="col-12 col-md-6 mt-3">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">
                          <h3>
                            Low
                            {/*<sup>
                                        <a target="_blank" href="/glossary?locale=en-GB#veryLowTopic" id="" className="">    <i className="fa fa-question-circle"></i></a>
                                      </sup>*/}
                          </h3>
                          <div className="m-auto bg-white p-5">

                            <p>
                              <label className="subject-target-ranges__label subject-target-ranges__label--very-low-value">

                                <select tabindex="0" id="" className="ml-0"
                                  onChange={(event) => {
                                    setVeryLow(event.target.value);
                                  }}
                                  defaultValue={settings && settings.low ? settings.low : ""}
                                  disabled
                                  >
                                  <option value="">Select</option>
                                  {settings && settings.low ?
                                    <option value={settings.low} selected>{settings.low}</option>
                                    : null}
                                  <option value="2.8" className="">
                                    2.8
                                  </option>
                                  <option value="2.9" className="">
                                    2.9
                                  </option>
                                  <option value="3.0" className="">
                                    3.0
                                  </option>
                                  <option value="3.1" className="">
                                    3.1
                                  </option>
                                  <option value="3.2" className="">
                                    3.2
                                  </option>
                                  <option value="3.3" className="">
                                    3.3
                                  </option>
                                  <option value="3.4" className="">
                                    3.4
                                  </option>
                                  <option value="3.5" className="">
                                    3.5
                                  </option>
                                  <option value="3.6" className="">
                                    3.6
                                  </option>
                                  <option value="3.7" className="">
                                    3.7
                                  </option>
                                  <option value="3.8" className="">
                                    3.8
                                  </option>
                                  <option value="3.9" className="">
                                    3.9
                                  </option>
                                  <option value="4.0" className="">
                                    4.0
                                  </option>


                                </select>
                              </label>
                            </p>
                          </div>
                        </div>


                      </div>
                      <div className="col-12 col-md-6 mt-3">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">

                          <h3>High</h3>
                          <div className="m-auto bg-white p-5">
                            <p>
                              <label className="very-high-value">

                                <select tabindex="0" id="" className="ml-0"
                                  onChange={(event) => {
                                    setVeryHigh(event.target.value);
                                  }} defaultValue={settings && settings.high ? settings.high : ""} disabled>
                                  <option value="">Select</option>
                                  {settings && settings.high ?
                                    <option value={settings.high} selected>{settings.high}</option>
                                    : null}
                                  <option value="10.2" className="">
                                    10.2
                                  </option>
                                  <option value="10.3" className="">
                                    10.3
                                  </option>
                                  <option value="10.4" className="">
                                    10.4
                                  </option>
                                  <option value="10.5" className="">
                                    10.5
                                  </option>
                                  <option value="10.6" className="">
                                    10.6
                                  </option>
                                  <option value="10.7" className="">
                                    10.7
                                  </option>
                                  <option value="10.8" className="">
                                    10.8
                                  </option>
                                  <option value="10.9" className="">
                                    10.9
                                  </option>
                                  <option value="11" className="">
                                    11.0
                                  </option>
                                  <option value="11.1" className="">
                                    11.1
                                  </option>
                                  <option value="11.2" className="">
                                    11.2
                                  </option>
                                  <option value="11.3" className="">
                                    11.3
                                  </option>
                                  <option value="11.4" className="">
                                    11.4
                                  </option>
                                  <option value="11.5" className="">
                                    11.5
                                  </option>
                                  <option value="11.6" className="">
                                    11.6
                                  </option>
                                  <option value="11.7" className="">
                                    11.7
                                  </option>
                                  <option value="11.8" className="">
                                    11.8
                                  </option>
                                  <option value="11.9" className="">
                                    11.9
                                  </option>
                                  <option value="12" className="">
                                    12.0
                                  </option>
                                  <option value="12.1" className="">
                                    12.1
                                  </option>
                                  <option value="12.2" className="">
                                    12.2
                                  </option>
                                  <option value="12.3" className="">
                                    12.3
                                  </option>
                                  <option value="12.4" className="">
                                    12.4
                                  </option>
                                  <option value="12.5" className="">
                                    12.5
                                  </option>
                                  <option value="12.6" className="">
                                    12.6
                                  </option>
                                  <option value="12.7" className="">
                                    12.7
                                  </option>
                                  <option value="12.8" className="">
                                    12.8
                                  </option>
                                  <option value="12.9" className="">
                                    12.9
                                  </option>
                                  <option value="13" className="">
                                    13.0
                                  </option>
                                  <option value="13.1" className="">
                                    13.1
                                  </option>
                                  <option value="13.2" className="">
                                    13.2
                                  </option>
                                  <option value="13.3" className="">
                                    13.3
                                  </option>
                                  <option value="13.4" className="">
                                    13.4
                                  </option>
                                  <option value="13.5" className="">
                                    13.5
                                  </option>
                                  <option value="13.6" className="">
                                    13.6
                                  </option>
                                  <option value="13.7" className="">
                                    13.7
                                  </option>
                                  <option value="13.8" className="">
                                    13.8
                                  </option>
                                  <option value="13.9" className="">
                                    13.9
                                  </option>
                                  <option value="14" className="">
                                    14.0
                                  </option>
                                  <option value="14.1" className="">
                                    14.1
                                  </option>
                                  <option value="14.2" className="">
                                    14.2
                                  </option>
                                  <option value="14.3" className="">
                                    14.3
                                  </option>
                                  <option value="14.4" className="">
                                    14.4
                                  </option>
                                  <option value="14.5" className="">
                                    14.5
                                  </option>
                                  <option value="14.6" className="">
                                    14.6
                                  </option>
                                  <option value="14.7" className="">
                                    14.7
                                  </option>
                                  <option value="14.8" className="">
                                    14.8
                                  </option>
                                  <option value="14.9" className="">
                                    14.9
                                  </option>
                                  <option value="15" className="">
                                    15.0
                                  </option>
                                  <option value="15.1" className="">
                                    15.1
                                  </option>
                                  <option value="15.2" className="">
                                    15.2
                                  </option>
                                  <option value="15.3" className="">
                                    15.3
                                  </option>
                                  <option value="15.4" className="">
                                    15.4
                                  </option>
                                  <option value="15.5" className="">
                                    15.5
                                  </option>
                                  <option value="15.6" className="">
                                    15.6
                                  </option>
                                  <option value="15.7" className="">
                                    15.7
                                  </option>
                                  <option value="15.8" className="">
                                    15.8
                                  </option>
                                  <option value="15.9" className="">
                                    15.9
                                  </option>
                                  <option value="16" className="">
                                    16.0
                                  </option>
                                  <option value="16.1" className="">
                                    16.1
                                  </option>
                                  <option value="16.2" className="">
                                    16.2
                                  </option>
                                  <option value="16.3" className="">
                                    16.3
                                  </option>
                                  <option value="16.4" className="">
                                    16.4
                                  </option>
                                  <option value="16.5" className="">
                                    16.5
                                  </option>
                                  <option value="16.6" className="">
                                    16.6
                                  </option>
                                  <option value="16.7" className="">
                                    16.7
                                  </option>
                                  <option value="16.8" className="">
                                    16.8
                                  </option>
                                  <option value="16.9" className="">
                                    16.9
                                  </option>
                                  <option value="17" className="">
                                    17.0
                                  </option>
                                  <option value="17.1" className="">
                                    17.1
                                  </option>
                                  <option value="17.2" className="">
                                    17.2
                                  </option>
                                  <option value="17.3" className="">
                                    17.3
                                  </option>
                                  <option value="17.4" className="">
                                    17.4
                                  </option>
                                  <option value="17.5" className="">
                                    17.5
                                  </option>
                                  <option value="17.6" className="">
                                    17.6
                                  </option>
                                  <option value="17.7" className="">
                                    17.7
                                  </option>
                                  <option value="17.8" className="">
                                    17.8
                                  </option>
                                  <option value="17.9" className="">
                                    17.9
                                  </option>
                                  <option value="18" className="">
                                    18.0
                                  </option>
                                  <option value="18.1" className="">
                                    18.1
                                  </option>
                                  <option value="18.2" className="">
                                    18.2
                                  </option>
                                  <option value="18.3" className="">
                                    18.3
                                  </option>
                                  <option value="18.4" className="">
                                    18.4
                                  </option>
                                  <option value="18.5" className="">
                                    18.5
                                  </option>
                                  <option value="18.6" className="">
                                    18.6
                                  </option>
                                  <option value="18.7" className="">
                                    18.7
                                  </option>
                                  <option value="18.8" className="">
                                    18.8
                                  </option>
                                  <option value="18.9" className="">
                                    18.9
                                  </option>
                                  <option value="19" className="">
                                    19.0
                                  </option>
                                  <option value="19.1" className="">
                                    19.1
                                  </option>
                                  <option value="19.2" className="">
                                    19.2
                                  </option>
                                  <option value="19.3" className="">
                                    19.3
                                  </option>
                                  <option value="19.4" className="">
                                    19.4
                                  </option>
                                  <option value="19.5" className="">
                                    19.5
                                  </option>
                                  <option value="19.6" className="">
                                    19.6
                                  </option>
                                  <option value="19.7" className="">
                                    19.7
                                  </option>
                                  <option value="19.8" className="">
                                    19.8
                                  </option>
                                  <option value="19.9" className="">
                                    19.9
                                  </option>
                                  <option value="20" className="">
                                    20.0
                                  </option>
                                  <option value="20.1" className="">
                                    20.1
                                  </option>
                                  <option value="20.2" className="">
                                    20.2
                                  </option>
                                  <option value="20.3" className="">
                                    20.3
                                  </option>
                                  <option value="20.4" className="">
                                    20.4
                                  </option>
                                  <option value="20.5" className="">
                                    20.5
                                  </option>
                                  <option value="20.6" className="">
                                    20.6
                                  </option>
                                  <option value="20.7" className="">
                                    20.7
                                  </option>
                                  <option value="20.8" className="">
                                    20.8
                                  </option>
                                  <option value="20.9" className="">
                                    20.9
                                  </option>
                                  <option value="21" className="">
                                    21.0
                                  </option>
                                  <option value="21.1" className="">
                                    21.1
                                  </option>
                                  <option value="21.2" className="">
                                    21.2
                                  </option>
                                  <option value="21.3" className="">
                                    21.3
                                  </option>
                                  <option value="21.4" className="">
                                    21.4
                                  </option>
                                  <option value="21.5" className="">
                                    21.5
                                  </option>
                                  <option value="21.6" className="">
                                    21.6
                                  </option>
                                  <option value="21.7" className="">
                                    21.7
                                  </option>
                                  <option value="21.8" className="">
                                    21.8
                                  </option>
                                  <option value="21.9" className="">
                                    21.9
                                  </option>

                                </select>

                              </label>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mt-3">
                        <div className="m-auto bg-white rounded shadow pt-2 pl-2">

                          <h3>Notifications</h3>
                          <div className="m-auto bg-white p-5">
                            {settings && settings.notifications === true ?
                              <label className="switch">
                                <input type="checkbox" name="notifications" defaultChecked={settings.notifications} onClick={(e) => { setAlerts(e, settings.notifications) }} disabled/>
                                <span className="slider round"></span>
                              </label>
                              :
                              <label className="switch">
                                <input type="checkbox" name="notifications" onChange={(e) => { setAlerts(e, settings.notifications) }} disabled/>
                                <span className="slider round"></span>
                              </label>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-12">
                <h1>
                  Delete Account
                </h1>
              </div>
              <div className="col-12 col-md-6 mt-4 mb-4">
                <div className="m-auto bg-white rounded shadow p-5">

                  <button type="button" className="btn btn-primary" onClick={() => {
                    startDeleteAccount()
                  }}> Delete Account</button>
                </div>
              </div>
              {/* <div className="col-12 text-center">
                  <button type="button" className="btn btn-primary"
                  onClick={()=>{
                    updateSettings();
                  }}>Update</button>
                  </div> */}


            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingComp;
