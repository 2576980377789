import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl } from "../../utils/urls";
import {
  FETCH_OVERLAY_START,
  FETCH_OVERLAY_FAILED,
  FETCH_OVERLAY_SUCCESS,
} from "../actionTypes/overlay";
import { findStartDate, convertDates, convertLastDates, } from "./blood-glucose";
import moment from "moment";

import { encrypt, decrypt } from "../../utils/encryption";

export const fetchOverlayData = (data) => async (dispatch) => {
  console.log("TIME DURATION",data);
  dispatch({
    type: FETCH_OVERLAY_START,
  });
  let dataForChart = [];
  let count;
  let week;
  let days;
  let orgData = [];
  let cgm_start;
  let daysDif = 1;
  let unit = data.unit;
  let bgTime;
  let type = 1;
  try {

    let overlayTimeEnc;
    let res;
    if (data.time) {
      bgTime = data.time.replace(/ /g, '');
      daysDif = data.time;
      let calculatedStartDate = await findStartDate(data.time);
      cgm_start = calculatedStartDate.date;
      let bg_start = calculatedStartDate.date;

      daysDif = calculatedStartDate.dif;
      bg_start = bg_start.toISOString();
      bg_start = await encrypt(bg_start);
      let bg_end = new Date();
      bg_end = bg_end.toISOString();
      bg_end = await encrypt(bg_end);
      let encType = type;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);
    } else if (data.start_date) {
      let bg_start = new Date(data.start_date);
      bg_start = bg_start.toISOString();

      let bg_end = new Date(data.end_date);
      bg_end = bg_end.toISOString();

      // Parse date strings using Moment.js
      const date1 = moment.utc(bg_start);
      const date2 = moment.utc(bg_end);
      let today = new Date();
      today = moment.utc(today);
      // Compare day, month, and year
      if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
        if (date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')) {
          bg_end = new Date();
          bg_end = bg_end.toISOString();
        } else {
          bg_end = convertLastDates(bg_end);
        }

      }
      // else {
      //   console.log("HERE ELSE", bg_end);
      //   bg_end= bg_end.toISOString();
      //
      // }

      bg_start = convertDates(data.start_date);
      cgm_start = new Date(bg_start);
      bg_start = await encrypt(bg_start);

      bg_end = await encrypt(bg_end);
      daysDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
      let encType = 1;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
      res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?startDate=${bg_start}&endDate=${bg_end}`);

    }
    let receivedData = res.data.data;
    receivedData = await decrypt(receivedData);
    receivedData = JSON.parse(receivedData);
    console.log("receivedData", receivedData);


    // Commenting to test other code

    let weeksArr = [];


    if (res.status === 200) {
      // console.log("IN Process", res);
      // if (res.data && res.data.data && receivedData.historicalReadings && receivedData.historicalReadings.length > 0) {
        let data = receivedData.historicalReadings;
        let firstWeekStartDate = moment(cgm_start);
        let numberOfWeeks = Math.ceil(daysDif / 7);
        console.log("numberOfWeeks", numberOfWeeks);
        let weekStartDate = "";
        let weekEndDate = "";
        for (let i = 0; i < numberOfWeeks; i++) {
          if (i === 0) {
            if (new Date(data.start_date) === new Date(data.end_date)) {
              // console.log("Start", data.startDate);
              // console.log("End", data.endDate);

              weekStartDate = moment(firstWeekStartDate).startOf('day')._d;
              weekEndDate = moment(firstWeekStartDate).endOf('day')._d;
              // console.log("weekStartDate", weekStartDate);
              // console.log("weekEndDate", weekEndDate);

            } else {
              weekStartDate = firstWeekStartDate;
              weekEndDate = moment(firstWeekStartDate).add(6, 'days');
            }

          }
          // let week=[];
          const week = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
          };
          if (data && data.length > 0) {
            let weekSD = weekStartDate;
            let weekED = weekEndDate;

            // New version

            data.forEach(day => {
              const readingTime = moment(day.time);
              const isWithinWeek = readingTime.isBetween(moment(weekSD), moment(weekED), 'day', '[]');


              if (isWithinWeek) {
                const dayOfWeek = readingTime.day();


                switch (dayOfWeek) {
                  case 0:
                    week.sunday.push(day);
                    break;
                  case 1:
                    week.monday.push(day);
                    break;
                  case 2:
                    week.tuesday.push(day);
                    break;
                  case 3:
                    week.wednesday.push(day);
                    break;
                  case 4:
                    week.thursday.push(day);
                    break;
                  case 5:
                    week.friday.push(day);
                    break;
                  case 6:
                    week.saturday.push(day);
                    break;
                  default:
                    break;
                }
                let conTime = moment(day.time).format("HH:mm");

                const obj = {
                  [moment(day.time).utc().format('dddd').toLowerCase()]: unit === 1 ? (day.value * 18.01).toFixed(1) : day.value.toFixed(1),
                  time2: conTime
                };

              } else {
              }
            });


          }
          const checkedEndDate = setToCurrentDateIfNeeded(weekEndDate);
          let obj = {
            weekStartDate: new Date(weekStartDate).toString(), weekEndDate: new Date(checkedEndDate).toString(),
            readings: [week]
          }
          weeksArr.push(obj);

          weekStartDate = moment(weekEndDate);
          weekEndDate = moment(weekEndDate).add(6, 'days');

        };


      // }
    };

    dispatch({ type: FETCH_OVERLAY_SUCCESS, payLoad: weeksArr });

    return weeksArr;
  } catch (error) {

    dispatch({
      type: FETCH_OVERLAY_FAILED,
    });
  }
};

const setToCurrentDateIfNeeded = (inputDate) => {
  const currentDate = moment();

  // Parse inputDate using Moment.js
  let parsedInputDate = moment(inputDate);

  // Check if the parsedInputDate is greater than the current date
  if (parsedInputDate.isAfter(currentDate)) {

    // Set the date to the current date
    parsedInputDate = currentDate;
  }

  // Return the result as a formatted string (adjust the format as needed)
  return parsedInputDate; // You can modify the format as per your requirement
}
