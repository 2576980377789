import { API_BASE_URL, getOTA, addOTA, deleteOTA } from "../../../utils/urls";
import { FETCH_OTA_START, FETCH_OTA_FAILED, FETCH_OTA_SUCCESS } from "../../actionTypes/ota";
import http from "../../../utils/http-service";
import ShowToast from "../../../utils/show-toast";
import { decrypt, encrypt } from "../../../utils/encryption";
import moment from "moment";

export const getOTAs = () => async (dispatch) => {
  dispatch({ type: FETCH_OTA_START })
  try {
    const res = await http.get(`${API_BASE_URL}${getOTA}`);
    let otas = res.data.data;
    otas = await decrypt(otas);
    otas = JSON.parse(otas);
    dispatch({ type: FETCH_OTA_SUCCESS, payload: otas });

    return otas;
  } catch (error) {

    dispatch({ type: FETCH_OTA_FAILED })

  }
}

// Redux action to make the API call
export const addOTAs = (data) => async (dispatch) => {
  try {
   
    const name = data.name;
    const major = data.major;
    const minor = data.minor;
    const patch = data.patch;
    const type = data.type;
    const date = moment(data.date).format("DDMMYYYY");
    const hour = data.hour;
    const version = `${name}-${major}-${minor}-${patch}-${type}-${date}-${hour}`;
   
    let encFormData = data;
    encFormData = JSON.stringify(encFormData);
    encFormData = await encrypt(encFormData);
 
    // Make the API request directly
    const res = await http.post(`${API_BASE_URL}${addOTA}`, { data: encFormData });

    return { status: true, version: version };
  } catch (error) {
 
    ShowToast(error.response.data.message, "error");


    return false;
  }
}


// Generate OTP
export const generateOTPs = () => async (dispatch) => {
  try {

    const res = await http.post(`${API_BASE_URL}/admin/requestOTP`);
    // Handle the API response here if needed

    return true;
  } catch (error) {


    return false;
  }
}
