
// export const API_BASE_URL = "http://127.0.0.1:8080";
// export const API_BASE_URL = "https://grxdiscovery.link";
// export const API_BASE_URL = "https://dev.e-ostre.com:8443";
// export const API_BASE_URL = "https://int.e-ostre.com:8443";
// export const API_BASE_URL = "https://dev.moduscgm.net";
// export const API_BASE_URL = "https://modusapi.discoveryportal.link:8443";
// export const API_BASE_URL = "https:modus-v2.grxsolver.com:8447"
export const API_BASE_URL = "https://modus-v2.grxsolver.com:2083"



export const loginURL = "/user/login";
// export const loginURL = "/user/e_login";

// export const createUserURL = "/user/signup";
export const createUserURL = "/user/e_signup";
// export const forgotPasswordURL = "/user/forgotPassword";
// export const forgotPasswordURL = "/user/e_forgotPassword";
export const forgotPasswordURL = "/user/forgotPassword";


// export const getEngineeringData = "/admin/e_getEngineeringData";
export const getEngineeringData = "/admin/getEngineeringData";


// export const getSensorLogsData = "/admin/getSensorLogsByUser";
// export const getSensorLogsData = "/admin/e_getSensorLogsByUser";
export const getSensorLogsData = "/admin/sensorLogs";




// export const getSessionDataUrl = "/admin/getEngineeringSessionData";
// export const getSessionDataUrl = "/admin/e_getEngineeringSessionData";
export const getSessionDataUrl = "/admin/sessions";



// export const getMeasurementDataUrl = "/admin/getEngineeringCsvData";
// export const deleteSessionUrl = "/admin/deleteSessionData";
export const deleteSessionUrl = "/admin/e_deleteSessionData";

// export const sensorLogsUrl = "/admin/getSensorLogsBySession";
export const sensorLogsUrl = "/admin/sensorLogs";




// export const getMeasurementDataUrl = "/admin/getEngineeringMeasurementData";
// export const getMeasurementDataUrl = "/admin/e_getEngineeringMeasurementData";
export const getMeasurementDataUrl = "/admin/measurements";


// export const getPointDataUrl = "/admin/getEngineeringPointData";
// export const getPointDataUrl = "/admin/e_getEngineeringPointData";
export const getPointDataUrl = "/admin/points";


export const getOTA = "/admin/firmware";
// export const getOTA = "/admin/e_getFirmware";

export const addOTA = "/admin/firmware";
// export const addOTA = "/admin/e_addFirmware";

export const deleteOTA = "/admin/removeFirmware";
// export const deleteOTA = "/admin/e_removeFirmware";


export const newEmailReq= "/user/updateEmailRequest";
export const verifyEmailReq = "/user/verifyUpdateEmailRequest";
export const newEmailConfirm= "/user/updateEmail";
export const newEmailConfirmReq= "/user/verifyUpdateEmailRequest";



// export const addSubjectsUrl = "/clinician/addSubjects";
// export const addSubjectsUrl = "/clinician/e_addSubjects";
export const addSubjectsUrl = "/clinicalTrial/subjects";



// export const getSubjects = "/clinician/getAllSubjects";
// export const getSubjects = "/clinician/e_getAllSubjects";
export const getSubjects = "/clinicalTrial/subjects";


// export const stopClinicalTrialUrl = "/clinician/stopClinicalTrial";
// export const stopClinicalTrialUrl = "/clinician/e_stopClinicalTrial";
export const stopClinicalTrialUrl = "/clinicalTrial";


// export const stopSubjectFromCTUrl = "/clinician/stopClinicalTrialForSubject";
// export const stopSubjectFromCTUrl = "/clinician/e_stopClinicalTrialForSubject";
export const stopSubjectFromCTUrl = "/clinicalTrial/stop";



// export const addSubToClinicalTrialUrl = "/clinician/addSubjectToClinicalTrial";
export const addSubToClinicalTrialUrl = "/clinicalTrial";


// export const deleteAccountURL = "/user/deleteAccount";
// export const deleteAccountURL = "/user/e_deleteAccount";
export const deleteAccountURL = "/user/";






// export const resetPasswordURL = "/user/resetPassword";
// export const resetPasswordURL = "/user/e_resetPassword";
export const resetPasswordURL = "/user/resetPassword";

// export const fetchUserURL = "/user/get";
// export const fetchUserURL = "/user/e_get";
export const fetchUserURL = "/user/";


// export const resendVerificationEmailURL = "/user/resend-email-verification";
export const resendVerificationEmailURL = "/user/verifyEmail";

// export const resendVerificationEmailURL = "/user/e_resend-email-verification";
// export const accountActicationUrl ="/user/e_activateAccount";
export const accountActicationUrl ="/user/activateAccount";




// export const fetchBgDataUrl = "/device/getData/";
// export const fetchBgDataUrl = "/device/e_getData/";
export const fetchBgDataUrl = "/device/data/";

// export const fetchSettingsUrl = "/settings/get";
// export const fetchSettingsUrl = "/settings/e_get";
export const fetchSettingsUrl = "/settings/";



// export const updateSettingsUrl = "/settings/update";
// export const updateSettingsUrl = "/settings/e_update";
export const updateSettingsUrl = "/settings/";






// // export const sendFeedback = "/user/feedback";
// export const sendFeedback = "/user/e_feedback";
// export const getSensorDetails = "/device/getSensorDetails";
// export const getSensorDetails = "/device/e_getSensorDetails";
export const getSensorDetails = "/device/last";


export const carbsUrl = "/carbs/get";
export const insulinUrl = "/insulin/get";
export const activityUrl = "/activity/get";
