import React, { useEffect, useState } from "react";

import {Pattern} from "@amcharts/amcharts4/core";
import {useDispatch, useSelector} from "react-redux";
import {getOTAs, addOTAs, generateOTPs} from "../../../redux/actions/admin/ota";
import {encrypt, decrypt} from "../../../utils/encryption";
import moment from "moment";

const Ota = () => {

  const [addModal, setAddModal] = useState(false);
  const [otaFileReceived, setOtaFileReceived] = useState([]);
  const {userDetails, selectedUser} = useSelector((state) => {return (state.user)});
  const {ota} = useSelector((state) => {return (state.ota)});
  console.log("OTA", ota);
  const [name, setName]= useState("");
  const [major, setMajor]= useState("");
  const [minor, setMinor]= useState("");
  const [patch, setPatch]= useState("");
  const [type, setType]= useState("");
  const [date, setDate]= useState("");
  const [hour, setHour]= useState("");
  const [otp, setOtp]= useState("");
  const [openVersionModal, setOpenVersionModal]= useState(false);
  const [showOta, setShowOta]= useState(true);
  const [otaVersion, setOtaVersion]= useState("");






  const [uploadedFile, setUploadFile]= useState("");
  const [description, setDescription]= useState("");
  const [selectedFile, setSelectedFile] = useState(null);



  const dispatch = useDispatch();
useEffect(()=>{
  fetchOtas();
},[]);
useEffect(()=>{// console.log("Change Detaacted", otaFileReceived);
// console.log("Selected Detaacted", selectedFile);

},[otaFileReceived, selectedFile])


const fetchOtas = async () => {
    let ress =await dispatch(getOTAs());
// console.log("RESS :", ress);
// setOtaData(ress);


};

const getUrl=(link)=>{
  let linkGenerated = decrypt(link).then((decrypted)=>{
    // console.log("Decreypted :", decrypted);
    // linkGenerated = JSON.parse(decrypted);
    // // console.log("User", linkGenerated);
setOtaFileReceived(decrypted);
  });
  // return linkGenerated;



}
// Function to handle the form submission
const addOTA = async (event) => {
  console.log("Event :", event.target);
  event.preventDefault();

  try {
    const data = {
      name: name,
      major: JSON.parse(major),
      minor: JSON.parse(minor),
      patch: JSON.parse(patch),
      type: JSON.parse(type),
      date: date,
      hour: hour.slice(0,2),
      description: description,
      otp: JSON.parse(otp),
    }
    // const formData = new FormData();

    // // Append the file and form fields to the formData object
    // formData.append("file", selectedFile);
    // formData.append("name", await encrypt(name));
    // formData.append("major", await encrypt(major));
    // formData.append("minor", await encrypt(minor));
    // formData.append("patch", await encrypt(patch));
    // formData.append("type", await encrypt(type));
    // formData.append("description", await encrypt(description));


    // console.log("FormData", formData);

    // Call the Redux action to make the API request
    const ress = await dispatch(addOTAs(data));
    if(ress.status=== true){
      setAddModal(false);
      setOpenVersionModal(true);
      setOtaVersion(ress.version); 
      event.target.reset();
    }
  } catch (e) {
    console.error("Error:", e);
  }
}
const generatingOTP = async () => {
  setShowOta(false);

  // Call the API to generate OTPs
  const resOtp = await dispatch(generateOTPs());
  console.log("OTP", resOtp);
  if(resOtp){
    setTimeout(()=>{setShowOta(true);}, 30000);
    
  } else { setShowOta(true);}
};

const handleCopy = (version) => {
  navigator.clipboard.writeText(version).then(() => {
    alert('Text copied to clipboard');
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
};
// const fileUpload =async (event) => {
//     const file = event.target.files[0];
//      // Get the selected file

//     if (file && file.name.endsWith('.zip')) {
//       setSelectedFile(file);
//     } else {
//       alert('Please select a .zip file.');
//     }
//   };

    return (
<>
{userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)?

        <div className="row mw-100 m-auto h-100 bg-white p-0">
          <div className="col-12 mb-3">
            <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add OTA</button>
          </div>
          <div className="col-12">
            <table className="table table-hover" id="task-table">
                <thead>
                  <tr>
                    <th>Date/Time</th>
                    <th>Major</th>
                    <th>Minor</th>
                    <th>Patch</th>
                    <th>Description</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  { ota && ota.firmwares && ota.firmwares.length > 0 && ota.firmwares.map((row, index)=>(
                      <tr key={index}>
                        <td>{moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                        <td> {row.major}</td>
                        <td> {row.minor}</td>
                        <td> {row.patch}</td>
                        <td>{row.description}</td>
                        <td><a href={`https://int.e-ostre.com:8443`+ `${otaFileReceived}`}>Download</a></td>
                      </tr>
                      ))}
                  </tbody>
            </table>
          </div>
        </div>:<div className="row mw-100 m-auto">
          <div className="col-12">
          <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
          </div></div>}
        <div className={`modal big_modal_dialog ${addModal? "d-block modal-fading" : " d-none"}`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"
          >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addHospitalModalTitle">
                    Add OTA
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>setAddModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <form className="pl-3 pr-3" onSubmit={(event)=>{addOTA(event)}}>
                  <div className="container my-4 ">


                    <div className="row justify-content-center align-items-center mb-3 text-white">

                      <div className="col-md-2 registration-input-labels"><p className="text-black">Name</p></div>
                      <div className="col-md-10">
                        <input


                          type="text"
                          className="registration-form-control text-black"
                          onChange={(e)=>setName(e.target.value)}
                          required
                        />
                      </div>
                    </div>



                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Major</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          onChange={(e)=>setMajor(e.target.value)}


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Minor</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          onChange={(e)=>setMinor(e.target.value)}


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Patch</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          onChange={(e)=>setPatch(e.target.value)}


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Type</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          onChange={(e)=>setType(e.target.value)}


                          className="registration-form-control text-black"

                          required
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Date</p></div>
                      <div className="col-md-10">
                        <input
                          type="date"
                          className="registration-form-control text-black"
                          onChange={(e)=>setDate(e.target.value)}

                          required
                        />

                      </div>
                    </div>

                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Hour</p></div>
                      <div className="col-md-10">
                        <input
                          type="time"
                          className="registration-form-control text-black"
                          onChange={(e)=>setHour(e.target.value)}

                          required
                        />

                      </div>
                    </div>


                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">Description</p></div>
                      <div className="col-md-10">
                        <textarea
                          type="textarea"
                          className="registration-form-control text-black"
                          onChange={(e)=>setDescription(e.target.value)}

                          required
                        />

                      </div>
                    </div>
                    <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels"><p className="text-black">OTP</p></div>
                      <div className="col-md-10">
                        <input
                          type="number"
                          min="0"
                          max="999999"
                          className="registration-form-control text-black"
                          onChange={(e)=>setOtp(e.target.value)}

                          required
                        />

                      </div>
                    </div>







                    {/* <div className="row justify-content-center align-items-center mb-3 text-white">
                      <div className="col-md-2 registration-input-labels "><p className="text-black">File</p></div>
                      <div className="col-md-10">
                        <div className="styled-select">
                          <input
                          type="file"
                          className="registration-form-control"
                          onChange={(e) => {
                            fileUpload(e);
                          }}
                          required
                          />
                        </div>

                      </div>
                    </div> */}

                  </div>
                  
                  <div className="modal-footer">
                    
                  <button className="btn btn-primary" type="submit">Add OTA</button>
                  {showOta?<button className="btn btn-primary" type="button" onClick={generatingOTP}>Generate OTP</button>:null}
                  </div>
                </form>
                </div>
              </div>
          </div>







































          <div className={`modal big_modal_dialog ${openVersionModal? "d-block modal-fading" : " d-none"}`}
              tabIndex="-1"
              data-backdrop="static"
              data-keyboard="false"

              role="dialog"

              aria-hidden="true"
          >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="addHospitalModalTitle">
                    Added OTA
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={()=>setOpenVersionModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>OTA Version : {otaVersion}  <button className="btn btn-primary" title="Copy" onClick={()=>{handleCopy(otaVersion)}}><i class="fa fa-copy"></i></button></h5>
                  </div>

                <div className="modal-footer">
                  <button className="btn btn-primary" type="button" onClick={()=>setOpenVersionModal(false)}>Close</button>
                  </div>  
                
                </div>
              </div>
          </div>
          </>



    );
};

export default Ota;
